// ============================================================================
// External Sites: Lindholmenstafetten
// ============================================================================

@import "1-settings";

// Fonts
$font-base: neuzeit-grotesk, sans-serif;
$font-complement: fira-sans-condensed, sans-serif;

// Colors

// Lindholmenstafetten
//
// Headings set in <a href="https://fonts.adobe.com/fonts/fira-sans">Fira Sans</a> and
// text set in <a href="https://fonts.adobe.com/fonts/neuzeit-grotesk">Neuzeit Grotesk</a>.
//
// Markup:
// <h3>Colors</h3>
// <div class="color-sample" style="background:#f47e3a">
//   <div style="color:#fff">#f47e3a</div>
// </div>
// <div class="color-sample" style="background:#5383a2">
//   <div style="color:#fff">#5383a2</div>
// </div>
// <div class="color-sample" style="background:#696971">
//   <div style="color:#fff">#696971</div>
// </div>
// <div style="clear: both"></div>
//
// Styleguide 7.2

$color-0: #fff;

$color-1: #f47e3a;
$color-2: #5383a2;
$color-3: #696971;
$color-4: #808080;
$color-5: #808080;
$color-6: #808080;
$color-7: #808080;

$color-0-text: #000;

$color-1-text: #fff;
$color-2-text: #fff;
$color-3-text: #fff;
$color-4-text: #000;
$color-5-text: #000;
$color-6-text: #000;
$color-7-text: #000;

$color-nav: #000;
$color-nav-bg: #fff;
$color-nav-active: #fff;
$color-nav-active-bg: $color-1;

@import "2-mixins";
@import "3-base";
@import "4-typography";
@import "5-layout";
@import "../6-blocks";

@import "print";

// ============================================================================
// Layout
// ============================================================================

body {
    background: #eee;
    color: #ccc;
}

main {
    background: #fff;
}

main > .section-main-introduction {
    background: $color-2;
    color: $color-2-text;
}

.top-image {
    height: 500px;

    @include respond-to($phone) {
        height: 500px;
    }

    @include respond-to($tablet) {
        height: 75vh;
    }
}

// ============================================================================
// Fonts and design elements
// ============================================================================

h1 {
    color: #333;
    font-weight: 400;
    text-transform: uppercase;
}

h2 {
    font-size: 1.5em;
    line-height: 1.2;
    font-weight: 400;
    text-transform: uppercase;

    @include respond-to($desktop) {
        font-size: 2em;
        line-height: 1.2;
    }
}

.header {
    .branding {
        .logo {
            padding-top: 5px;
            padding-bottom: 5px;
            width: 40%;

            @include respond-to($widescreen) {
                width: 50%;
            }
        }
    }

    .cta {
        width: 80%;
        margin: 0 10%;
    }
}

.top-image {
    background-color: #294c60;
}

.info {
    font-family: $font-complement;

    h2 {
        text-transform: none;
    }
}

.section {
    color: #000;
}

.section h2 span {
    color: $color-2;
}
