// ============================================================================
// Element: Buttons
// ============================================================================

// Buttons
//
// These are the different kinds of buttons used on the site. We use the same
// visual states for <code>.disabled</code> and <code>.disabled:hover</code> to
// indicate that the button can't be interacted with.
//
// :hover           - hover state
// :focus           - focus state
// :active          - active state
// .disabled        - disabled state
// .disabled:hover  - disabled hover state
//
// Markup:
// <h3>Default</h3>
// <div style="margin: 1em 0; outline: 1px solid rgba(255, 255, 255, 0.2);">
// <div style="padding: 1em;">
// <button class="button {{modifier_class}}">Normal action</button>
// <button class="button button--primary {{modifier_class}}">Primary</button>
// <button class="button button--secondary {{modifier_class}}">Secondary</button>
// <button class="button button--white {{modifier_class}}">White</button>
// <button class="button button--black {{modifier_class}}">Black</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <button class="button button--large {{modifier_class}}">Call to action</button>
// <button class="button button--large--secondary {{modifier_class}}">Call to action</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <a href="#" class="button button--primary {{modifier_class}}">Link</a>
// <a href="#" class="button button--secondary {{modifier_class}}">Link</a>
// </div>
// </div>
// <h3>Background colors</h3>
// <div class="color-0" style="background: #eee; margin: 1em 0;">
// <div style="padding: 1.5em 1em 1em 1em;">
// <button class="button {{modifier_class}}">Normal action</button>
// <button class="button button--primary {{modifier_class}}">Primary</button>
// <button class="button button--secondary {{modifier_class}}">Secondary</button>
// <button class="button button--white {{modifier_class}}">White</button>
// <button class="button button--black {{modifier_class}}">Black</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <button class="button button--large {{modifier_class}}">Call to action</button>
// <button class="button button--large--secondary {{modifier_class}}">Call to action</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <a href="#" class="button button--primary {{modifier_class}}">Link</a>
// <a href="#" class="button button--secondary {{modifier_class}}">Link</a>
// </div>
// </div>
// <div class="color-1" style="margin: 1em 0;">
// <div style="padding: 1.5em 1em 1em 1em;">
// <button class="button {{modifier_class}}">Normal action</button>
// <button class="button button--primary {{modifier_class}}">Primary</button>
// <button class="button button--secondary {{modifier_class}}">Secondary</button>
// <button class="button button--white {{modifier_class}}">White</button>
// <button class="button button--black {{modifier_class}}">Black</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <button class="button button--large {{modifier_class}}">Call to action</button>
// <button class="button button--large--secondary {{modifier_class}}">Call to action</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <a href="#" class="button button--primary {{modifier_class}}">Link</a>
// <a href="#" class="button button--secondary {{modifier_class}}">Link</a>
// </div>
// </div>
// <div class="color-2" style="margin: 1em 0;">
// <div style="padding: 1.5em 1em 1em 1em;">
// <button class="button {{modifier_class}}">Normal action</button>
// <button class="button button--primary {{modifier_class}}">Primary</button>
// <button class="button button--secondary {{modifier_class}}">Secondary</button>
// <button class="button button--white {{modifier_class}}">White</button>
// <button class="button button--black {{modifier_class}}">Black</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <button class="button button--large {{modifier_class}}">Call to action</button>
// <button class="button button--large--secondary {{modifier_class}}">Call to action</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <a href="#" class="button button--primary {{modifier_class}}">Link</a>
// <a href="#" class="button button--secondary {{modifier_class}}">Link</a>
// </div>
// </div>
// <div class="color-3" style="margin: 1em 0;">
// <div style="padding: 1.5em 1em 1em 1em;">
// <button class="button {{modifier_class}}">Normal action</button>
// <button class="button button--primary {{modifier_class}}">Primary</button>
// <button class="button button--secondary {{modifier_class}}">Secondary</button>
// <button class="button button--white {{modifier_class}}">White</button>
// <button class="button button--black {{modifier_class}}">Black</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <button class="button button--large {{modifier_class}}">Call to action</button>
// <button class="button button--large--secondary {{modifier_class}}">Call to action</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <a href="#" class="button button--primary {{modifier_class}}">Link</a>
// <a href="#" class="button button--secondary {{modifier_class}}">Link</a>
// </div>
// </div>
// <div class="color-4" style="margin: 1em 0;">
// <div style="padding: 1.5em 1em 1em 1em;">
// <button class="button {{modifier_class}}">Normal action</button>
// <button class="button button--primary {{modifier_class}}">Primary</button>
// <button class="button button--secondary {{modifier_class}}">Secondary</button>
// <button class="button button--white {{modifier_class}}">White</button>
// <button class="button button--black {{modifier_class}}">Black</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <button class="button button--large {{modifier_class}}">Call to action</button>
// <button class="button button--large--secondary {{modifier_class}}">Call to action</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <a href="#" class="button button--primary {{modifier_class}}">Link</a>
// <a href="#" class="button button--secondary {{modifier_class}}">Link</a>
// </div>
// </div>
// <div class="color-5" style="margin: 1em 0; outline: 1px solid rgba(255, 255, 255, 0.2);">
// <div style="padding: 1.5em 1em 1em 1em;">
// <button class="button {{modifier_class}}">Normal action</button>
// <button class="button button--primary {{modifier_class}}">Primary</button>
// <button class="button button--secondary {{modifier_class}}">Secondary</button>
// <button class="button button--white {{modifier_class}}">White</button>
// <button class="button button--black {{modifier_class}}">Black</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <button class="button button--large {{modifier_class}}">Call to action</button>
// <button class="button button--large--secondary {{modifier_class}}">Call to action</button>
// </div>
// <div style="padding: 0 1em 1em 1em;">
// <a href="#" class="button button--primary {{modifier_class}}">Link</a>
// <a href="#" class="button button--secondary {{modifier_class}}">Link</a>
// </div>
// </div>
//
// Styleguide 4.1

.button,
.button-wrapper a {
    font-feature-settings: "pnum" 1, "lnum" 1;

    @supports (font-variant-numeric: proportional-nums) {
        font-feature-settings: normal;
        font-variant-numeric: proportional-nums lining-nums;
    }

    font-family: $font-base;
    font-size: 0.85em;
    border: 1px solid $color-1;
    background: $color-1;
    color: $color-1-text;
    padding: 12px 28px 12px 28px;
    margin: 0 10px 15px 0;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 3px;
    text-decoration: none;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    line-height: 1.5;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03),
    0 2px 2px rgba(0, 0, 0, 0.03),
    0 4px 4px rgba(0, 0, 0, 0.03),
    0 6px 8px rgba(0, 0, 0, 0.03),
    0 8px 16px rgba(0, 0, 0, 0.03);

    &:hover {
        background-color: lighten($color-1, 5);
        border: 1px solid lighten($color-1, 5);
        color: $color-1-text;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11),
        0 2px 2px rgba(0, 0, 0, 0.11),
        0 4px 4px rgba(0, 0, 0, 0.11),
        0 6px 8px rgba(0, 0, 0, 0.11),
        0 8px 16px rgba(0, 0, 0, 0.11);
    }

    &:active {
        background-color: lighten($color-4, 8);
        border: 1px solid lighten($color-4, 8);
        color: $color-1-text;
    }

    &:focus {
        @include outline();

        text-decoration: underline;
    }

    &.disabled,
    &.disabled:hover {
        background: #eee;
        border-color: #eee;
        color: #aaa;
        box-shadow: none;

        .icon {
            opacity: 0.5;
        }
    }

    .icon {
        float: left;
        display: inline-block;
        width: 24px;
        height: 24px;
        background-size: 100%;
        margin: -2px 7px 0 -4px;
    }

    /* Primary action button, i.e. 'Ok' */
    &--primary {
        border: 1px solid $color-1;
        background: $color-1;
        color: #fff;
        min-width: 80px;
        text-align: center;

        &:hover {
            background: lighten($color-1, 5);
            border: 1px solid lighten($color-1, 5);
            color: #fff;
        }

        &:active {
            @include outline();

            background: lighten($color-1, 8);
            border: 1px solid lighten($color-1, 8);
            color: #fff;
        }

        &.disabled,
        &.disabled:hover {
            border: 1px solid lighten(#000, 50%);
            text-shadow: none;
        }

        .color-1 &,
        .color-2 &,
        .color-3 &,
        .color-4 &,
        .color-5 & {
            border: 1px solid #fff;
            background: #fff;
            color: #000;

            &:hover {
                border: 1px solid #fff;
                background: #fafafa;
                color: #000;
            }
        }
    }

    /* Secondary action button, i.e. 'Cancel' */
    &--secondary {
        border: 1px solid $color-1;
        background: transparent;
        color: $color-1;
        min-width: 80px;
        text-align: center;
        box-shadow: none;

        &:hover {
            border: 1px solid #000;
            background-color: transparent;
            color: #000;
        }

        &:active {
            border: 1px solid darken($color-1, 8);
        }

        &.disabled,
        &.disabled:hover {
            box-shadow: none;
            border: 1px solid lighten(#000, 70%);
        }

        // White on transparent for colored backgrounds
        .color-1 &,
        .color-2 &,
        .color-3 &,
        .color-4 &,
        .color-5 & {
            border: 1px solid #fff;
            background: transparent;
            color: #fff;

            &:hover {
                border: 1px solid #fff;
                background: #fff;
                color: #000;
            }

            &.disabled,
            &.disabled:hover {
                background: #eee;
                border-color: #eee;
                color: #aaa;
            }
        }
    }

    /* White */
    &--white {
        border: 1px solid #fff;
        background: #fff;
        color: #000;

        &:hover {
            border: 1px solid #fff;
            background: #fff;
            color: #000;
        }

        &:active {
            border: 1px solid #fff;
            background: #fff;
            color: #000;
        }

        &.disabled:hover {
            color: rgb(170, 170, 170);
        }

        // White on transparent for colored backgrounds
        .color-1 &,
        .color-2 &,
        .color-3 &,
        .color-4 &,
        .color-5 & {
            background: transparent;
            color: #fff;

            &:hover {
                border: 1px solid #fff;
                background: #fff;
                color: #000;
            }

            &.disabled,
            &.disabled:hover {
                background: #eee;
                border-color: #eee;
                color: #aaa;
            }
        }
    }

    /* Black */
    &--black {
        border: 1px solid #000;
        background: #000;
        color: #fff;

        &:hover {
            border: 1px solid #000;
            background: #000;
            color: #fff;
        }

        &:active {
            border: 1px solid #000;
            background: #000;
            color: #fff;
        }

        &.disabled,
        &.disabled:hover {
            color: lighten(#000, 50%);
        }

        // White on transparent for colored backgrounds
        .color-1 &,
        .color-2 &,
        .color-3 &,
        .color-4 &,
        .color-5 & {
            background: transparent;
            color: #000;

            &:hover {
                border: 1px solid #000;
                background: #000;
                color: #fff;
            }

            &.disabled,
            &.disabled:hover {
                background: #eee;
                border-color: #eee;
                color: #aaa;
            }
        }
    }

    /* Large Call-to-Action */
    &--large {
        font-size: 1.2rem;
        padding: 10px 28px;
        border: 0;
        background-color: #d66d00;
        background-image: linear-gradient(180deg, #ff8200 0%, #d66d00 100%);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
        border-radius: 2px;

        &:hover {
            border: 0;
            transform: scale(1.05);
            box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
        }

        &.disabled:hover {
            transform: scale(1);
            box-shadow: none;
        }
    }

    /* Large Secondary Call-to-Action */
    &--large--secondary {
        font-size: 1.2rem;
        padding: 10px 28px;
        border: 0;
        background-color: #efefef;
        background-image: linear-gradient(0deg, #efefef 0%, #fff 100%);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        color: #000;

        &:hover {
            border: 0;
            transform: scale(1.05);
            box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
            color: #000;
        }

        &:active {
            color: #000;
        }

        &.disabled:hover {
            transform: scale(1);
            box-shadow: none;
        }
    }
}

// Make sure inline links styled like a button remains inline
a.button {
    display: inline-block;
}
