// ============================================================================
// Module: Text and Image
// ============================================================================

.module-partnertexts {
    margin: 0;
    .partner {
        margin: 80px 0 80px 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 0;
        justify-content: center;
        overflow: hidden;
        padding-top: 1em; // No Grid
        padding-bottom: 1em; // No Grid
        @supports (display: grid) {
            padding-top: 0;
            padding-bottom: 0;
        }

        @include respond-to($tablet) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px;
            align-items: center;
        }
    }

    .text {
        padding-left: 50px;

        @supports (display: grid) {
            padding-left: 0;
        }
    }

    .text,
    .media {
        float: left;
        width: 50%;

        @supports (display: grid) {
            width: auto;
        }
    }

    // Right aligned media
    .media--right {
        @include respond-to($tablet) {
            .text,
            .media {
                float: right;
                width: 50%;

                @supports (display: grid) {
                    width: auto;
                }
            }

            .text {
                grid-column: 1;
                grid-row: 1;
                padding-right: 50px;

                @supports (display: grid) {
                    padding-right: 0;
                }
            }

            .media {
                grid-column: 2;
            }
        }
    }

    figure {
        display: flex;
        justify-content: center;
        line-height: 0.01;

        img {
            width: 100%;
        }
    }

    h2 {
        margin-top: 1rem;
        max-width: 30ch;

        @include respond-to($tablet) {
            margin-top: 0;
            padding-top: 0;
        }
    }

    p {
        max-width: 60ch;
    }

    .media + .text {
        margin-top: 2rem;

        @include respond-to($tablet) {
            margin-top: 0;
        }
    }
}

.external .module-partnertexts figure {
    border: 1px solid #eee;
    padding: 3px;
}
