// ============================================================================
// Module: Horizontal rule
// ============================================================================

.module-hr {
    &.section {
        margin: 50px 0;

        @include respond-to($tablet) {
            margin: 50px 200px;
        }

        @include respond-to($desktop) {
            margin: 150px 0;
        }
    }

    &.color-grey hr {
        border-color: #d8d8d8;
    }

    &.color-1 {
        background: none;

        hr {
            border-color: $color-1;
        }
    }
}
