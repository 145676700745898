// ============================================================================
// Block: District map
// ============================================================================

.section.module-district-map {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #fff;

    @include respond-to($tablet) {
        background-color: unset;
        margin-top: unset;
        margin-bottom: unset;
        padding-bottom: unset;
    }

    .course-profile-image {
        display: none;
        width: 100%;
        padding: 0 60px;

        @include respond-to($tablet) {
            display: block;
        }
    }

    .districts-overview {
        position: relative;
        height: 400px;
        margin-bottom: 50px;

        &::after {
            content: "";
            display: block;
            background: $color-4;
            left: 8px;
            width: 4px;
            height: 400px;
            position: absolute;
        }

        @include respond-to($tablet) {
            height: 100px;
            padding: 0 50px;
            width: 100%;

            &::after {
                background: $color-4;
                top: calc(50% - 2px);
                left: unset;
                width: calc(100% - 100px);
                height: 4px;
            }
        }

        .mobile-district-image {
            display: none;
            position: absolute;
            top: calc((400px / 2) - 75px);
            right: 0;
            width: 150px;
            height: 150px;

            img {
                border-radius: 50%;
            }

            &.selected {
                display: block;
            }

            @include respond-to(370px) {
                width: 200px;
                height: 200px;
                top: calc((400px / 2) - 100px);
            }

            @include respond-to(430px) {
                width: 250px;
                height: 250px;
                top: calc((400px / 2) - 125px);
            }

            @include respond-to($large-phone) {
                width: 300px;
                height: 300px;
                top: calc((400px / 2) - 150px);
            }

            @include respond-to($tablet) {
                display: none;

                &.selected {
                    display: none;
                }
            }
        }
    }

    button.district-tab {
        border-radius: 50%;
        max-height: 20px;
        max-width: 20px;
        padding: 0;
        margin: 0;
        background: $color-4;
        height: 20px;
        width: 20px;
        position: absolute;
        z-index: 1;
        border: 2px solid #F6F4F2;
        cursor: pointer;


        &.pulse-animation {
            animation-name: pulse;
            animation-duration: 0.6s;
            animation-timing-function: ease-out;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            animation-play-state: running;
        }

        &.selected {
            background-color: #AB1C46;

            span.tab-title {
                color: #AB1C46;
            }
        }

        span.tab-title {
            position: absolute;
            font-family: $font-base;
            font-weight: 800;
            font-size: 12px;
            left: 30px;
            top: -2px
        }

        &.end,
        &.start {
            span.tab-title {
                font-family: $font-complement;
                font-weight: unset;
                font-size: 16px;
            }
        }
    }

    @keyframes pulse {
        0% {
            background-color: $color-1;
        }

        100% {
            //transform: scale(1.05);
            //border-width: 6px;
        }
    }

    @include respond-to($tablet) {
        button.district-tab {
            top: calc(50% - 10px);
            display: flex;
            justify-content: center;

            span.tab-title {
                top: unset;
                left: unset;
                position: relative;
                margin-top: 20px;
            }

            &:nth-of-type(2n) {
                span.tab-title {
                    top: unset;
                    bottom: unset;
                    margin-top: -26px;
                }
            }

            &.end {
                right: 50px;

                span.tab-title {
                    position: absolute;
                    left: unset;
                    right: -50px;
                    top: -2px;
                    margin-top: 0;
                }
            }

            &.start {
                left: 50px;

                span.tab-title {
                    position: absolute;
                    left: -50px;
                    top: -2px;
                    margin-top: 0;
                }
            }

        }
    }

    .districts {
        position: relative;
        padding-bottom: 50px;

        #districts-select {
            position: absolute;
            width: 150px;
            bottom: 0;
            padding: 6px 10px;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            left: calc(50% - 75px);
            cursor: pointer;

            @include respond-to($tablet) {
                right: 40px;
                top: 40px;
                left: unset;
                bottom: unset;
            }
        }
    }

    .district {
        background-color: #fff;
        padding: 0 10px;

        @include respond-to($tablet) {
            padding: 70px 40px 50px 40px;
        }

        &.hidden {
            display: none;
        }

        .district-title {
            min-height: 40px;

            &.hide-on-mobile {
                display: none;
            }

            @include respond-to($tablet) {
                &.hide-on-mobile {
                    display: block;
                }

                &.hide-on-desktop {
                    display: none;
                }

                &.hide-on-desktop.hide-on-mobile {
                    display: none;
                }
            }
        }

        .text-and-image {
            .district-image {
                display: block;
                margin-bottom: 20px;

                @include respond-to($tablet) {
                    float: right;
                    max-width: calc(50% - 50px);
                    margin: 0 0 40px 50px;
                }
            }

            .district-bottom-image {
                display: none;

                @include respond-to($tablet) {
                    display: block;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
