// ============================================================================
// Block: Video
// ============================================================================

.block-video {
    .section-content {
        @supports (display: grid) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-gap: 20px 20px;
        }
    }

    .item {
        margin-bottom: 20px;

        @supports (display: grid) {
            grid-column: span 2;
            margin: 0;
        }

        @include respond-to($large-phone) {
            &.width-50 {
                width: 46%;
                float: left;
                margin-left: 2%;
                margin-right: 2%;

                @supports (display: grid) {
                    grid-column: span 1;
                    width: auto;
                    float: none;
                    margin: 0;
                }
            }
        }
    }

    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed,
    .embed-container video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.type-project .block-video {
    margin-top: -30px;
}
