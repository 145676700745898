// ============================================================================
// External Sites: Layout
// ============================================================================

body {
    background: #fff;
    margin: 0;
    padding: 0;

    &.grid::after {
        border-top: 10px solid #dade0a;
        content: '';
        position: fixed;
        top: 0;
        z-index: 10000;
        height: 100vh;
        width: 100%;
        background: url(/assets/images/grid-mobile.png) repeat-y center top;
        background-size: 100%;

        @include respond-to($large-phone) {
            border-top: 10px solid #f4c90e;
            content: 'large-phone';
        }

        @include respond-to($tablet) {
            background: url(/assets/images/grid-tablet.png) repeat-y center top;
            background-size: 100%;
            border-top: 10px solid #db8710;
            content: 'tablet';
        }

        @include respond-to($desktop) {
            background: url(/assets/images/grid-desktop.png) repeat-y center top;
            background-size: 100%;
            border-top: 10px solid #cf000e;
            content: 'desktop';
        }

        @include respond-to($widescreen) {
            background: url(/assets/images/grid-widescreen.png) repeat-y center top;
            background-size: 100%;
            border-top: 10px solid #950024;
            max-width: $layout-max-width;
            left: 50%;
            margin-left: -960px;
            content: 'widescreen';
        }
    }
}

// Skip to main link
.skip-to-main-content[href="#main"] {
    background: rgba(255, 255, 255, 0.9);
    color: #000;
    position: absolute;
    top: 10px;
    right: 1000%;
    padding: 10px;
    margin: 5px;

    &:focus {
        right: auto;
    }
}

// Hidden HTML attribute
[hidden] {
    display: none;
}

// Visually hidden
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
}

// Images
.lazyload.blur-up {
    filter: blur(5px);
    opacity: 0.3;
    transition: opacity 0s, filter 0.7s;
}

.lazyload.blur-up.lazyloaded {
    filter: blur(0);
    opacity: 1;
    transition: opacity 0.5s;
}

// ============================================================================
// Header
// ============================================================================

.header {
    width: 100%;

    .header-content {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        margin: 0 auto;
        padding: 0;

        @include respond-to($tablet) {
            padding: 0 20px;
        }

        @include respond-to($desktop) {
            padding: 0 30px;
        }

        @include respond-to(1300px) {
            padding: 0 cp($grid-desktop-margin, $grid-desktop-width);
        }

        @include respond-to($widescreen) {
            max-width: $layout-max-width;
            padding: 0 240px;
        }
    }

    .branding {
        position: absolute;
        z-index: 100;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
        top: 100px;
        left: 50%;
        width: 220px;
        margin-left: -110px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01),
        0 2px 2px rgba(0, 0, 0, 0.01),
        0 4px 4px rgba(0, 0, 0, 0.01),
        0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.01);

        @include respond-to($phone) {
            top: 120px;
        }

        @include respond-to($tablet) {
            width: 420px;
            top: 140px;
            margin-left: -210px;
        }

        @include respond-to($desktop) {
            width: auto;
            margin-left: 0;
            top: initial;
            left: 30px;
        }

        @include respond-to(1300px) {
            left: cp($grid-desktop-margin, $grid-desktop-width);
        }

        @include respond-to($widescreen) {
            left: cp($grid-widescreen-margin, $grid-widescreen-width);
        }

        .logo {
            display: block;
            padding: 15px;
            margin: 20px auto;
            line-height: 0;
            color: #fff;
            width: 50%;

            @supports (display: grid) {
                max-width: 150px;
            }

            @include respond-to($tablet) {
                @supports (display: grid) {
                    max-width: 200px;
                }
            }

            @media screen and (min-width: $tablet) and (min-height: 900px) {
                @supports (display: grid) {
                    max-width: 255px;
                }
            }

            &:focus {
                outline: 1px dotted #000;
            }
        }
    }

    nav {
        position: absolute;
        background: $color-nav-bg;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include respond-to($tablet) {
            width: auto;
            right: 20px;
        }

        @include respond-to($desktop) {
            right: 30px;
        }

        @include respond-to(1300px) {
            right: cp($grid-desktop-margin, $grid-desktop-width);
        }

        @include respond-to($widescreen) {
            right: cp($grid-widescreen-margin, $grid-widescreen-width);
        }
    }
}

// Information - used both in header and underneath on mobile
.info {
    color: $color-3;

    @include respond-to($tablet) {
        padding: 0 32px 32px 32px;
    }

    h2 {
        margin-top: 0;
        text-align: center;

        span {
            color: #000;
        }
    }

    // Used on mobile
    main & {
        margin: 20px;

        @include respond-to($tablet) {
            display: none;
        }

        h2 {
            color: #000;
            margin: 30px 0 15px 0;
        }
    }

    // Used on tablet+
    .header & {
        @include respond-to($desktop) {
            min-width: 330px;
        }

        h2 {
            width: 100%;
            padding: 15px 20px 8px 20px;
            border-top: 1px solid #d8d8d8;

            @include respond-to($tablet) {
                border-bottom: 1px solid #d8d8d8;
            }
        }

        ul {
            display: none;

            @include respond-to($tablet) {
                display: block;
                margin: 2em 24px;
                font-size: 0.95em;
            }
        }
    }
}

// Call to Action  - used both in header and underneath on mobile
.cta {
    display: block;
    box-sizing: border-box;
    width: 60%;
    border: 0;
    padding: 13px 30px;
    background: $color-1;
    color: $color-1-text;
    font-family: $font-base;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.1em;
    transition: all 0.3s;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04),
    0 2px 2px rgba(0, 0, 0, 0.04),
    0 4px 4px rgba(0, 0, 0, 0.04),
    0 8px 8px rgba(0, 0, 0, 0.04),
    0 16px 16px rgba(0, 0, 0, 0.04);

    &:hover,
    &:focus {
        cursor: pointer;
        background: darken($color-1, 5);
        color: #fff;
        transform: scale(1.05);
    }

    // Used on mobile
    main & {
        margin: 10px 20% 20px 20%;

        @include respond-to($tablet) {
            display: none;
        }
    }

    // Used on tablet+
    .header & {
        display: none;

        @include respond-to($tablet) {
            display: block;
            margin: 0 20%;
            font-size: 16px;
        }
    }
}

// Top image
.top-image {
    width: 100%;
    height: 340px;
    text-align: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to($phone) {
        height: 400px;
    }

    @include respond-to($tablet) {
        height: 75vh;
    }

    @include respond-to($desktop) {
        height: 90vh;
        max-height: 900px;
    }

    img {
        width: 50%;
        max-width: 100%;
        height: auto;

        @include respond-to($tablet) {
            width: 300px;
        }
    }
}

// ============================================================================
// Navigation
// ============================================================================

.main-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    overflow-x: hidden;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01),
    0 2px 2px rgba(0, 0, 0, 0.01),
    0 4px 4px rgba(0, 0, 0, 0.01),
    0 8px 8px rgba(0, 0, 0, 0.01),
    0 16px 16px rgba(0, 0, 0, 0.01);

    li {
        display: inline;
        width: 100%;

        &.highlighted a {
            background: $color-nav-active-bg;
            color: $color-nav-active;
        }
    }

    a {
        display: block;
        background: $color-nav-bg;
        color: $color-nav;
        padding: 15px 15px;
        text-decoration: none;
        text-align: center;
        white-space: nowrap;

        @include respond-to($tablet) {
            padding: 15px 20px;
        }

        @include respond-to($desktop) {
            padding: 15px 40px;
        }

        &:hover {
            color: $color-nav;
            text-decoration: underline;
        }
    }

    > li + li a {
        border-left: 1px solid #d8d8d8;
    }
}

.floating-nav {
    .main-nav {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03),
        0 2px 2px rgba(0, 0, 0, 0.03),
        0 4px 4px rgba(0, 0, 0, 0.03),
        0 8px 8px rgba(0, 0, 0, 0.03),
        0 16px 16px rgba(0, 0, 0, 0.03);
    }

    .branding {
        display: none !important;
    }
}

// ============================================================================
// Content
// ============================================================================

// General section rules
.section {
    clear: both;
    background-size: cover;
    margin: 0;
    padding: 0;

    .section-content {
        box-sizing: border-box;
        width: 100%;
        margin: 0 auto;
        padding: 0 cp($grid-mobile-margin + $grid-mobile-column + $grid-mobile-gutter, $grid-mobile-width);

        @include respond-to($tablet) {
            padding: 0 cp($grid-tablet-margin, $grid-tablet-width);
        }

        @include respond-to($desktop) {
            padding: 0 cp($grid-desktop-margin, $grid-desktop-width);
        }

        @include respond-to($widescreen) {
            max-width: $layout-max-width;
            padding: 0 240px;
        }
    }

    & .no-heading {
        padding: 10px ($grid-mobile-margin + $grid-mobile-column + $grid-mobile-gutter)px;

        @include respond-to($tablet) {
            padding: 10px cp($grid-tablet-margin, $grid-tablet-width);
        }

        @include respond-to($desktop) {
            padding: 10px cp($grid-desktop-margin, $grid-desktop-width);
        }

        @include respond-to($widescreen) {
            padding: 10px cp($grid-widescreen-margin, $grid-widescreen-width);
        }
    }

    &.color-1 {
        background: $color-1;
        color: $color-1-text;
        border-color: $color-1-text;

        a {
            color: $color-1-text;
        }
    }

    &.color-2 {
        background: $color-2;
        color: $color-2-text;
        border-color: $color-2-text;

        a {
            color: $color-2-text;
        }
    }

    &.color-3 {
        background: $color-3;
        color: $color-3-text;
        border-color: $color-3-text;

        a {
            color: $color-3-text;
        }
    }
}

// Content modules
main > .section {
    margin: 30px 0 30px 0;

    @include respond-to($tablet) {
        margin: 80px 0;
    }

    a:hover {
        color: $color-1;
    }
}

main > .section:last-child {
    margin-bottom: 0;
}

// Block: Icons
main > .section-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    font-size: 16px;
    text-align: center;
    font-weight: 600;

    @include respond-to($desktop) {
        justify-content: space-evenly;
    }

    .icon {
        flex: 1 0 0;
        min-width: 150px;
        min-height: 200px;
        padding: 30px 20px;
        color: #696971;

        @include respond-to($phone) {
            &:nth-child(1),
            &:nth-child(2) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }

            &:nth-child(odd) {
                border-right: 1px solid rgba(0, 0, 0, 0.1);
            }
        }

        @include respond-to(450px) {
            border: 0;

            &:nth-child(1),
            &:nth-child(2) {
                border-bottom: 0;
            }

            &:nth-child(odd) {
                border: 0;
            }
        }

        @include respond-to(700px) {
            margin: 20px 0;
            border-right: 1px solid rgba(0, 0, 0, 0.1);

            &:nth-child(odd) {
                border-right: 1px solid rgba(0, 0, 0, 0.1);
            }

            &:first-child {
                border-left: 1px solid rgba(0, 0, 0, 0.1);
            }
        }

        img {
            padding-bottom: 10px;
        }

        small {
            color: #000;
        }
    }
}

// Block: Introduction
main > .section-main-introduction {
    margin: 0;
    padding: 0;
    background: #eee;
    color: #000;
    overflow: hidden;

    .section-content {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0;

        @include respond-to($tablet) {
            grid-template-columns: 1fr 1fr;
            align-items: center;

            .text,
            .image {
                float: left;
                width: 50%;

                @supports (display: grid) {
                    width: auto;
                }
            }
        }

        .text {
            padding: 1em cp($grid-mobile-margin + $grid-mobile-column + $grid-mobile-gutter, $grid-mobile-content);

            @include respond-to($tablet) {
                padding: 1em cp($grid-tablet-margin + $grid-tablet-column + $grid-tablet-gutter, $grid-tablet-content);
                margin-top: 2em; // No Grid

                @supports (display: grid) {
                    margin-top: 0;
                }
            }

            @include respond-to($desktop) {
                padding: 1em 50px 1em cp($grid-desktop-margin, $grid-desktop-width);

                @supports (display: grid) {
                    padding: 1em 50px 1em cp($grid-desktop-margin + $grid-desktop-column + $grid-desktop-gutter, $grid-desktop-content);
                }
            }

            @include respond-to($widescreen) {
                padding: 1em 100px 1em 240px;
            }

            p {
                max-width: 70ch;
            }
        }

        .image {
            display: none;

            @include respond-to($tablet) {
                display: block;
            }

            figure {
                line-height: 0;
            }
        }

        h1 {
            @include respond-to($tablet) {
                margin-top: 0;
            }
        }

        p {
            @include respond-to($tablet) {
                margin-bottom: 0;
            }
        }
    }
}

// Block: Long text
main > .section-longtext {
    padding-bottom: 10em;

    @include respond-to($tablet) {
        margin: cp($grid-tablet-column + $grid-tablet-gutter, $grid-tablet-content);
    }

    @include respond-to($desktop) {
        margin: 0 cp(2 * $grid-desktop-column + 2 * $grid-desktop-gutter, $grid-desktop-content);
    }

    h2 {
        margin-top: 2em;
    }

    h3 {
        text-transform: uppercase;
        margin-top: 2em;
    }

    ul {
        margin: 0 0 2em -10px;
        padding: 0;
    }

    li {
        margin: 0;
        padding: 0 0 0 10px;
    }
}

// ============================================================================
// Effects
// ============================================================================

.section-main-introduction figure,
.section.module-textandmedia picture {
    position: relative;
    overflow: hidden;

    img {
        transition: all 0.6s ease-out;
    }

    &:hover img {
        transform: scale(1.1) rotateZ(-1deg);
    }
}

// ============================================================================
// Footer
// ============================================================================

footer {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 40px cp($grid-mobile-margin + $grid-mobile-column + $grid-mobile-gutter, $grid-mobile-width);
    margin: 0 auto;
    background: $color-footer-bg;
    color: $color-footer-text;
    text-align: center;
    font-size: 0.8em;

    p {
        margin: 0;
        padding: 0;
    }

    a {
        color: #fff;

        &:hover {
            color: $color-1;
        }
    }

    .social-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 1em 0;

        li {
            list-style-type: none;
        }
    }

    .icon {
        display: block;
        width: 30px;
        height: 30px;
        margin: 8px;
    }

    .terms-and-conditions {
        margin: 2em 0;
        padding: 0;

        li {
            display: inline;
        }

        a {
            display: inline-block;
            padding: 4px;
        }
    }
}

// ============================================================================
// Plugins - FSLightbox
// ============================================================================

.fslightbox-slide-btn-container-next {
    .fslightbox-slide-btn {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTAgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYxICg4OTU4MSkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+bGlnaHRib3gtYXJyb3ctcmlnaHQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0ibGlnaHRib3gtYXJyb3ctcmlnaHQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJsdmwyLWFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjAwMDAwMCwgMS4wMDAwMDApIiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aCIgcG9pbnRzPSItMi43NTMzNTMxZS0xNCAwIDcuNSA3LjUgLTIuNzUzMzUzMWUtMTQgMTUiPjwvcG9seWxpbmU+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
    }
}

.fslightbox-slide-btn-container-previous {
    .fslightbox-slide-btn {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTAgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYxICg4OTU4MSkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+bGlnaHRib3gtYXJyb3ctbGVmdDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJsaWdodGJveC1hcnJvdy1sZWZ0IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0ibHZsMi1hcnJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNS4wMDAwMDAsIDguNTAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC01LjAwMDAwMCwgLTguNTAwMDAwKSB0cmFuc2xhdGUoMS4wMDAwMDAsIDEuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlBhdGgiIHBvaW50cz0iLTIuNzUzMzUzMWUtMTQgMCA3LjUgNy41IC0yLjc1MzM1MzFlLTE0IDE1Ij48L3BvbHlsaW5lPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
    }
}

.fslightbox-slide-btn {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 10px !important;

    svg {
        visibility: hidden;
    }
}

// Hide image counter
.fslightbox-slide-number-container {
    display: none !important;
}

// Hide fullscreen button
.fslightbox-toolbar {
    .fslightbox-toolbar-button:first-child {
        display: none;
    }
}
