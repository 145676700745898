// ============================================================================
// External Sites: Base
// ============================================================================

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-play-state: paused !important;
        transition: none !important;
        scroll-behavior: auto !important;
    }
}

/* Remove ~300 ms delay on Mobile Safari for the following elements */
a,
button,
input,
select,
textarea,
label,
summary {
    touch-action: manipulation;
}

html {
    @include font-smoothing(on);

    font-size: 17px;
    font-family: $font-base;

    @include respond-to($large-phone) {
        font-size: 17px;
    }

    @include respond-to($desktop) {
        font-size: 18px;
    }
}

body {
    scroll-behavior: smooth;
    color: $color-text;
    background: #fff;

    // Enable ligatures as default
    font-feature-settings: "liga" 1;
    @supports (font-variant-ligatures: common-ligatures) {
        font-feature-settings: normal;
        font-variant-ligatures: common-ligatures;
    }
}

.wf-loading h1,
.wf-loading h2,
.wf-loading h3,
.wf-loading p {
    display: none;
}

// ============================================================================
// Typography
// ============================================================================

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-complement;
    font-weight: 700;
}

p,
ul,
li {
    font-weight: 400;

    // Old style figures in running text and nice looking fractions
    font-feature-settings: "onum" 1;
    @supports (font-variant-numeric: oldstyle-nums) {
        font-feature-settings: normal;
        font-variant-numeric: oldstyle-nums;
    }
}

p,
ul,
ol,
li,
h1,
h2,
h3,
h4 {
    margin: 0 0 1.45em 0;
    padding: 0;
    font-size: 1em;
    line-height: 1.45;

    @include respond-to($tablet) {
        margin: 0 0 1.5em 0;
        line-height: 1.55;
    }
}

ul ul,
ol ol,
dl dl {
    margin-bottom: 0;
}

img {
    max-width: 100%;
    height: auto;
}

ul,
ol,
dl {
    margin: 0 0 1.5em 0;
    line-height: 1.5;
}

ul,
ol {
    margin-left: 20px;
}

li {
    margin-bottom: 0;
}

dt {
    font-weight: bold;
    color: $color-heading;
}

dd {
    margin: 0 0 1em 0;
}

a {
    color: $color-link;
    transition: 0.3s color;

    &:hover {
        color: $color-hover;
    }
}

b,
strong {
    font-weight: 600;
}

pre {
    padding: 20px;
    background: #eee;
    color: #333;
    font-size: 0.75em;
    line-height: 1.6;
    margin-bottom: 3em;
}

code {
    font-size: 1em;
}

// Superscripts
sup {
    font-feature-settings: "sups" 1;

    @supports (font-variant-position: super) {
        font-feature-settings: normal;
        font-variant-position: super;
    }
    @supports ((font-variant-position: super) or (font-feature-settings: "sups" 1)) {
        vertical-align: inherit;
        font-size: inherit;
    }
}

// Subscripts
sub {
    font-feature-settings: "subs" 1;

    @supports (font-variant-position: sub) {
        font-feature-settings: normal;
        font-variant-position: sub;
    }
    @supports ((font-variant-position: sub) or (font-feature-settings: "subs" 1)) {
        vertical-align: inherit;
        font-size: inherit;
    }
}

// ============================================================================
// Tables
// ============================================================================

table {
    margin: 1.5em 0;
    border-collapse: collapse;
    caption-side: bottom;

    & .number {
        text-align: right;
    }

    & .alignment {
        text-align: "." center;
    }
}

caption {
    padding: 0.5em 5px 1.5em 5px;
    color: #666;
    font-size: 0.8em;
    font-style: italic;
}

th {
    font-variant-numeric: lining-nums tabular-nums;
    font-weight: bold;
    text-align: left;
    padding: 0.25em 2em 0.25em 0.5em;
    font-size: 0.8em;
    color: #333;
    // border-bottom: 1px solid #000;

    @include respond-to($tablet) {
        font-size: 1em;
        padding: 0.25em 2em 0.25em 0.5em;
    }

    &:last-child {
        padding-right: 0.5em;
    }
}

tfoot th {
    text-align: left;
}

td {
    font-variant-numeric: lining-nums tabular-nums;
    text-align: left;
    padding: 0.25em 2em 0.25em 0.5em;
    font-size: 0.8em;
    color: #333;
    border: 0;
    // border-bottom: 1px solid #ddd;

    tr.no-border & {
        border: 0;
    }

    @include respond-to($tablet) {
        font-size: 1em;
        line-height: 1.3;
        padding: 0.25em 2em 0.25em 0.5em;
    }
    @include respond-to($desktop) {
        font-size: 1em;
    }

    &:last-child {
        padding-right: 0.5em;
    }
}

// ============================================================================
// General
// ============================================================================

hr {
    margin: 1em 0;
    border: 0;
    border-top: 1px solid #eee;

    @include respond-to($large-phone) {
        margin: 4em 0 3em 0;
    }
}

.hr hr {
    display: none;
}

.hr {
    margin: 1em 0;
    border-top: 1px solid #ddd;

    @include respond-to($large-phone) {
        margin: 6em 0 1em 0;
    }
}

blockquote {
    margin: 2em 0 2em 25px;
    color: $color-text;
    font-style: italic;

    @include respond-to($tablet) {
        margin: 0 0 1em 50px;
        padding: 0 20px;
    }

    p {
        quotes: '“' '”' '‘' '’';

        &::before {
            content: open-quote;
            margin-left: -0.83ch;
        }

        &::after {
            content: close-quote;
        }
    }
}

figure {
    margin: 0;
}

figcaption {
    color: #666;
    font-size: 0.8em;
    font-style: italic;
    margin: 0.5em 0 2em 0;
}

object {
    outline: none;
}

.small {
    float: right;
    margin-right: 20px;
    font-size: 11px;
}

.center {
    text-align: center;
}

picture.right {
    float: right;
    margin: 0 0 2em 4em;
}

picture.left {
    float: left;
    margin: 0 2em 4em 0;
}

// ============================================================================
// Forms
// ============================================================================

fieldset {
    margin: 0 0 3em 0;
    padding: 1em;
    border: 1px solid $color-text;

    @include respond-to($large-phone) {
        padding: 1.5em;
    }
}

legend {
    padding: 0 2px;
    color: $color-text;
    font-family: $font-complement;
    font-weight: normal;
}

label {
    font-family: $font-complement;
    font-weight: normal;
    display: block;
    margin: 13px 0 3px 0;
    font-size: 0.85em;

    &.long {
        margin: 15px 0;
        line-height: 0.9;
    }
}

input {
    font-family: $font-base;
    font-weight: normal;
    color: #333;
    font-size: 16px;

    &[type="text"],
    &[type="email"],
    &[type="password"],
    &[type="number"],
    &[type="tel"],
    &[type="url"],
    &[type="search"] {
        -webkit-appearance: none;
        box-sizing: border-box;
        margin: 0;
        padding: 7px;
        border: 1px solid #ccc;
        width: 100%;

        @include respond-to($tablet) {
            max-width: 600px;
        }
    }

    &[type="number"] {
        text-align: center;
        padding: 5px 3px;
        width: 45px;

        @include respond-to($tablet) {
            padding: 5px;
            width: 60px;
        }
    }

    &[type="color"] {
        padding: 0;
    }

    &[type="file"] {
        font-size: 12px;
    }

    &[readonly="readonly"] {
        color: #ccc;
    }

    &[disabled] {
        background: #eee;
        color: #333;
    }

    &:focus:not([readonly]) {
        // background: #fcfddd;
        @include outline();
    }

    &.submit {
        margin: 25px 0 10px 0;
    }
}


textarea {
    font-family: $font-base;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    padding: 5px;
    border: 1px solid #ccc;
    font-size: 16px;

    @include respond-to($large-phone) {
        max-width: 600px;
    }

    &:focus {
        @include outline();
        // -webkit-transition: all 0.6s ease-in-out;
        // -moz-transition: all 0.6s ease-in-out;
        // -o-transition: all 0.6s ease-in-out;
        // transition: all 0.6s ease-in-out;
    }

    &:focus:not([readonly]) {
        // background: #fcfddd;
        // height: 150px;
    }
}

select {
    margin: 2px 0 10px 0;
}

::-webkit-input-placeholder {
    font-size: 16px;
    color: #999;
}

:-moz-placeholder { /* Firefox 18- */
    color: #999;
}

::-moz-placeholder { /* Firefox 19+ */
    color: #999;
}

:-ms-input-placeholder {
    color: #999;
}

// ============================================================================
// Responsive video
// ============================================================================

.video-container {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 56.25%;
    margin: 1em 0;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
