@charset "UTF-8";
/*
MOBILE (12 COL GRID):       5 + 12 x (24 + 2)  +   5  =>   320 px
TABLET (12 COL GRID):      22 + 12 x (45 + 40) +  22  =>  1115 px
DESKTOP (12 COL GRID):    240 + 12 x (60 + 40) + 240  =>  1920 px
WIDESCREEN (12 COL GRID): 240 + 12 x (60 + 40) + 240  =>  1920 px
*/
.font--serifa {
  font-family: serifa, serif;
}

.vertical-rhythm {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px);
  background-size: 100% 1.45em;
}

.no-ligatures {
  font-feature-settings: "liga" 0;
}
@supports (font-variant-ligatures: no-common-ligatures) {
  .no-ligatures {
    font-feature-settings: normal;
    font-variant-ligatures: no-common-ligatures;
  }
}

.discretionary-ligatures {
  font-feature-settings: "dlig" 1, "hist" 1;
}
@supports (font-variant-ligatures: discretionary-ligatures historical-ligatures) {
  .discretionary-ligatures {
    font-feature-settings: normal;
    font-variant-ligatures: discretionary-ligatures historical-ligatures;
  }
}

@supports (font-variant-numeric: diagonal-fractions) {
  .diagonal-fractions {
    font-feature-settings: normal;
    font-variant-numeric: diagonal-fractions;
  }
}

.small-caps {
  font-feature-settings: "smcp" 1, "c2sc" 1;
}
@supports (font-variant-caps: all-small-caps) {
  .small-caps {
    font-feature-settings: normal;
    font-variant-caps: all-small-caps;
  }
}

.proportional-nums {
  font-feature-settings: "pnum" 1;
}
@supports (font-variant-numeric: proportional-nums) {
  .proportional-nums {
    font-feature-settings: normal;
    font-variant-numeric: proportional-nums;
  }
}

.tabular-nums {
  font-feature-settings: "tnum" 1, "zero" 1;
}
@supports (font-variant-numeric: tabular-nums) {
  .tabular-nums {
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums slashed-zero;
  }
}

.oldstyle-nums {
  font-feature-settings: "onum" 1;
}
@supports (font-variant-numeric: oldstyle-nums) {
  .oldstyle-nums {
    font-feature-settings: normal;
    font-variant-numeric: oldstyle-nums;
  }
}

.lining-nums {
  font-feature-settings: "lnum" 1;
}
@supports (font-variant-numeric: lining-nums) {
  .lining-nums {
    font-feature-settings: normal;
    font-variant-numeric: lining-nums;
  }
}

.proportional-lining-nums {
  font-feature-settings: "pnum" 1, "lnum" 1;
}
@supports (font-variant-numeric: proportional-nums) {
  .proportional-lining-nums {
    font-feature-settings: normal;
    font-variant-numeric: proportional-nums lining-nums;
  }
}

.proportional-oldstyle-nums {
  font-feature-settings: "pnum" 1, "onum" 1;
}
@supports (font-variant-numeric: proportional-nums) {
  .proportional-oldstyle-nums {
    font-feature-settings: normal;
    font-variant-numeric: proportional-nums oldstyle-nums;
  }
}

.tabular-lining-nums {
  font-feature-settings: "tnum" 1, "lnum" 1;
}
@supports (font-variant-numeric: tabular-nums) {
  .tabular-lining-nums {
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums lining-nums;
  }
}

.tabular-oldstyle-nums {
  font-feature-settings: "tnum" 1, "onum" 1;
}
@supports (font-variant-numeric: tabular-nums) {
  .tabular-oldstyle-nums {
    font-feature-settings: normal;
    font-variant-numeric: tabular-nums oldstyle-nums;
  }
}

.color-sample {
  float: left;
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 20px 20px 0;
}
.color-sample--small {
  width: 130px;
  height: 130px;
}

.color-sample div {
  padding: 10px;
  color: inherit;
}

.color-1 {
  background: #1d4f91;
  color: #fff;
}

.color-2 {
  background: #e4002b;
  color: #fff;
}

.color-3 {
  background: #6e6259;
  color: #fff;
}

.color-4 {
  background: #ff8200;
  color: #222;
}

.color-5 {
  background: #59cbe8;
  color: #222;
}

.color-6 {
  background: #009f4d;
  color: #222;
}

.color-7 {
  background: #f99fc9;
  color: #222;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-play-state: paused !important;
    transition: none !important;
    scroll-behavior: auto !important;
  }
}
/* Remove ~300 ms delay on Mobile Safari for the following elements */
a,
button,
input,
select,
textarea,
label,
summary {
  touch-action: manipulation;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  font-family: neuzeit-grotesk, sans-serif;
}
@media screen and (min-width: 600px) {
  html {
    font-size: 17px;
  }
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 18px;
  }
}

body {
  scroll-behavior: smooth;
  color: #333;
  background: #fff;
  font-feature-settings: "liga" 1;
}
@supports (font-variant-ligatures: common-ligatures) {
  body {
    font-feature-settings: normal;
    font-variant-ligatures: common-ligatures;
  }
}

.wf-loading h1,
.wf-loading h2,
.wf-loading .sportmassan .module-sponsors h2.text-small,
.sportmassan .module-sponsors .wf-loading h2.text-small,
.wf-loading h3,
.wf-loading p {
  display: none;
}

h1,
h2,
.sportmassan .module-sponsors h2.text-small,
h3,
h4,
h5,
h6 {
  font-family: fira-sans-condensed, sans-serif;
  font-weight: 700;
}

p,
ul,
li {
  font-weight: 400;
  font-feature-settings: "onum" 1;
}
@supports (font-variant-numeric: oldstyle-nums) {
  p,
ul,
li {
    font-feature-settings: normal;
    font-variant-numeric: oldstyle-nums;
  }
}

p,
ul,
ol,
li,
h1,
h2,
.sportmassan .module-sponsors h2.text-small,
h3,
h4 {
  margin: 0 0 1.45em 0;
  padding: 0;
  font-size: 1em;
  line-height: 1.45;
}
@media screen and (min-width: 768px) {
  p,
ul,
ol,
li,
h1,
h2,
.sportmassan .module-sponsors h2.text-small,
h3,
h4 {
    margin: 0 0 1.5em 0;
    line-height: 1.55;
  }
}

ul ul,
ol ol,
dl dl {
  margin-bottom: 0;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol,
dl {
  margin: 0 0 1.5em 0;
  line-height: 1.5;
}

ul,
ol {
  margin-left: 20px;
}

li {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
  color: #1d4f91;
}

dd {
  margin: 0 0 1em 0;
}

a {
  color: #000;
  transition: 0.3s color;
}
a:hover {
  color: #333;
}

b,
strong {
  font-weight: 600;
}

pre {
  padding: 20px;
  background: #eee;
  color: #333;
  font-size: 0.75em;
  line-height: 1.6;
  margin-bottom: 3em;
}

code {
  font-size: 1em;
}

sup {
  font-feature-settings: "sups" 1;
}
@supports (font-variant-position: super) {
  sup {
    font-feature-settings: normal;
    font-variant-position: super;
  }
}
@supports (font-variant-position: super) or (font-feature-settings: "sups" 1) {
  sup {
    vertical-align: inherit;
    font-size: inherit;
  }
}

sub {
  font-feature-settings: "subs" 1;
}
@supports (font-variant-position: sub) {
  sub {
    font-feature-settings: normal;
    font-variant-position: sub;
  }
}
@supports (font-variant-position: sub) or (font-feature-settings: "subs" 1) {
  sub {
    vertical-align: inherit;
    font-size: inherit;
  }
}

table {
  margin: 1.5em 0;
  border-collapse: collapse;
  caption-side: bottom;
}
table .number {
  text-align: right;
}
table .alignment {
  text-align: "." center;
}

caption {
  padding: 0.5em 5px 1.5em 5px;
  color: #666;
  font-size: 0.8em;
  font-style: italic;
}

th {
  font-variant-numeric: lining-nums tabular-nums;
  font-weight: bold;
  text-align: left;
  padding: 0.25em 2em 0.25em 0.5em;
  font-size: 0.8em;
  color: #333;
}
@media screen and (min-width: 768px) {
  th {
    font-size: 1em;
    padding: 0.25em 2em 0.25em 0.5em;
  }
}
th:last-child {
  padding-right: 0.5em;
}

tfoot th {
  text-align: left;
}

td {
  font-variant-numeric: lining-nums tabular-nums;
  text-align: left;
  padding: 0.25em 2em 0.25em 0.5em;
  font-size: 0.8em;
  color: #333;
  border: 0;
}
tr.no-border td {
  border: 0;
}
@media screen and (min-width: 768px) {
  td {
    font-size: 1em;
    line-height: 1.3;
    padding: 0.25em 2em 0.25em 0.5em;
  }
}
@media screen and (min-width: 1024px) {
  td {
    font-size: 1em;
  }
}
td:last-child {
  padding-right: 0.5em;
}

hr {
  margin: 1em 0;
  border: 0;
  border-top: 1px solid #eee;
}
@media screen and (min-width: 600px) {
  hr {
    margin: 4em 0 3em 0;
  }
}

.hr hr {
  display: none;
}

.hr {
  margin: 1em 0;
  border-top: 1px solid #ddd;
}
@media screen and (min-width: 600px) {
  .hr {
    margin: 6em 0 1em 0;
  }
}

blockquote {
  margin: 2em 0 2em 25px;
  color: #333;
  font-style: italic;
}
@media screen and (min-width: 768px) {
  blockquote {
    margin: 0 0 1em 50px;
    padding: 0 20px;
  }
}
blockquote p {
  quotes: "“" "”" "‘" "’";
}
blockquote p::before {
  content: open-quote;
  margin-left: -0.83ch;
}
blockquote p::after {
  content: close-quote;
}

figure {
  margin: 0;
}

figcaption {
  color: #666;
  font-size: 0.8em;
  font-style: italic;
  margin: 0.5em 0 2em 0;
}

object {
  outline: none;
}

.small {
  float: right;
  margin-right: 20px;
  font-size: 11px;
}

.center {
  text-align: center;
}

picture.right {
  float: right;
  margin: 0 0 2em 4em;
}

picture.left {
  float: left;
  margin: 0 2em 4em 0;
}

fieldset {
  margin: 0 0 3em 0;
  padding: 1em;
  border: 1px solid #333;
}
@media screen and (min-width: 600px) {
  fieldset {
    padding: 1.5em;
  }
}

legend {
  padding: 0 2px;
  color: #333;
  font-family: fira-sans-condensed, sans-serif;
  font-weight: normal;
}

label {
  font-family: fira-sans-condensed, sans-serif;
  font-weight: normal;
  display: block;
  margin: 13px 0 3px 0;
  font-size: 0.85em;
}
label.long {
  margin: 15px 0;
  line-height: 0.9;
}

input {
  font-family: neuzeit-grotesk, sans-serif;
  font-weight: normal;
  color: #333;
  font-size: 16px;
}
input[type=text], input[type=email], input[type=password], input[type=number], input[type=tel], input[type=url], input[type=search] {
  -webkit-appearance: none;
  box-sizing: border-box;
  margin: 0;
  padding: 7px;
  border: 1px solid #ccc;
  width: 100%;
}
@media screen and (min-width: 768px) {
  input[type=text], input[type=email], input[type=password], input[type=number], input[type=tel], input[type=url], input[type=search] {
    max-width: 600px;
  }
}
input[type=number] {
  text-align: center;
  padding: 5px 3px;
  width: 45px;
}
@media screen and (min-width: 768px) {
  input[type=number] {
    padding: 5px;
    width: 60px;
  }
}
input[type=color] {
  padding: 0;
}
input[type=file] {
  font-size: 12px;
}
input[readonly=readonly] {
  color: #ccc;
}
input[disabled] {
  background: #eee;
  color: #333;
}
input:focus:not([readonly]) {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 130, 0, 0.5);
}
input.submit {
  margin: 25px 0 10px 0;
}

textarea {
  font-family: neuzeit-grotesk, sans-serif;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}
@media screen and (min-width: 600px) {
  textarea {
    max-width: 600px;
  }
}
textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 130, 0, 0.5);
}
select {
  margin: 2px 0 10px 0;
}

::-webkit-input-placeholder {
  font-size: 16px;
  color: #999;
}

:-moz-placeholder { /* Firefox 18- */
  color: #999;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #999;
}

:-ms-input-placeholder {
  color: #999;
}

.video-container {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
  margin: 1em 0;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h1 {
  margin: 1.75em 0 0.5em 0;
  font-size: 1.6em;
  line-height: 1.2;
  font-weight: 900;
}
@media screen and (min-width: 600px) {
  h1 {
    margin: 0 0 0.75em 0;
    font-size: 2em;
  }
}
@media screen and (min-width: 1024px) {
  h1 {
    margin: 0 0 0.75em 0;
    font-size: 2.5em;
  }
}
@media screen and (min-width: 1920px) {
  h1 {
    margin: 0 0 0.75em 0;
    font-size: 3em;
  }
}

h2, .sportmassan .module-sponsors h2.text-small {
  margin: 1.5em 0 0.3em 0;
  padding: 0.3em 0;
  font-size: 1.2em;
  line-height: 1.4;
}
@media screen and (min-width: 600px) {
  h2, .sportmassan .module-sponsors h2.text-small {
    margin: 1.8em 0 0.6em 0;
    font-size: 2.45em;
    line-height: 1.2;
  }
}

h3 {
  margin: 1em 0 0.5em 0;
  font-size: 1em;
}

h4,
h5,
h6 {
  margin: 1em 0 0.5em 0;
  font-size: 0.9em;
  color: #666;
}

small,
.text-small {
  font-size: 10px;
  text-transform: uppercase;
}

.text-medium {
  font-size: 14px;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .text-medium {
    font-size: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .text-medium {
    font-size: 20px;
  }
}

.text-navigation {
  font-family: fira-sans-condensed, sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .text-navigation {
    font-size: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .text-navigation {
    font-size: 14px;
  }
}
@media screen and (min-width: 1920px) {
  .text-navigation {
    font-size: 16px;
  }
}

.section h2 span, .section .sportmassan .module-sponsors h2.text-small span, .sportmassan .module-sponsors .section h2.text-small span, .text-subheading {
  font-family: fira-sans-condensed, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #f47e3a;
  margin-bottom: 1em;
}
@media screen and (min-width: 768px) {
  .section h2 span, .section .sportmassan .module-sponsors h2.text-small span, .sportmassan .module-sponsors .section h2.text-small span, .text-subheading {
    font-size: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .section h2 span, .section .sportmassan .module-sponsors h2.text-small span, .sportmassan .module-sponsors .section h2.text-small span, .text-subheading {
    font-size: 21px;
  }
}

.section .introduction {
  font-size: 1.2em;
}

.section h1,
.section h2,
.section .sportmassan .module-sponsors h2.text-small,
.sportmassan .module-sponsors .section h2.text-small,
.section p {
  color: inherit;
}
.section h1 {
  margin-top: 1em;
}
@media screen and (min-width: 1024px) {
  .section h2, .section .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .section h2.text-small {
    margin-top: 0;
  }
}
.section h2 span, .section .sportmassan .module-sponsors h2.text-small span, .sportmassan .module-sponsors .section h2.text-small span {
  display: block;
}
body {
  background: #fff;
  margin: 0;
  padding: 0;
}
body.grid::after {
  border-top: 10px solid #dade0a;
  content: "";
  position: fixed;
  top: 0;
  z-index: 10000;
  height: 100vh;
  width: 100%;
  background: url(/assets/images/grid-mobile.png) repeat-y center top;
  background-size: 100%;
}
@media screen and (min-width: 600px) {
  body.grid::after {
    border-top: 10px solid #f4c90e;
    content: "large-phone";
  }
}
@media screen and (min-width: 768px) {
  body.grid::after {
    background: url(/assets/images/grid-tablet.png) repeat-y center top;
    background-size: 100%;
    border-top: 10px solid #db8710;
    content: "tablet";
  }
}
@media screen and (min-width: 1024px) {
  body.grid::after {
    background: url(/assets/images/grid-desktop.png) repeat-y center top;
    background-size: 100%;
    border-top: 10px solid #cf000e;
    content: "desktop";
  }
}
@media screen and (min-width: 1920px) {
  body.grid::after {
    background: url(/assets/images/grid-widescreen.png) repeat-y center top;
    background-size: 100%;
    border-top: 10px solid #950024;
    max-width: 1920px;
    left: 50%;
    margin-left: -960px;
    content: "widescreen";
  }
}

.skip-to-main-content[href="#main"] {
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  position: absolute;
  top: 10px;
  right: 1000%;
  padding: 10px;
  margin: 5px;
}
.skip-to-main-content[href="#main"]:focus {
  right: auto;
}

[hidden] {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.lazyload.blur-up {
  filter: blur(5px);
  opacity: 0.3;
  transition: opacity 0s, filter 0.7s;
}

.lazyload.blur-up.lazyloaded {
  filter: blur(0);
  opacity: 1;
  transition: opacity 0.5s;
}

.header {
  width: 100%;
}
.header .header-content {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .header .header-content {
    padding: 0 20px;
  }
}
@media screen and (min-width: 1024px) {
  .header .header-content {
    padding: 0 30px;
  }
}
@media screen and (min-width: 1300px) {
  .header .header-content {
    padding: 0 12.5%;
  }
}
@media screen and (min-width: 1920px) {
  .header .header-content {
    max-width: 1920px;
    padding: 0 240px;
  }
}
.header .branding {
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  top: 100px;
  left: 50%;
  width: 220px;
  margin-left: -110px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.01), 0 8px 8px rgba(0, 0, 0, 0.01), 0 16px 16px rgba(0, 0, 0, 0.01);
}
@media screen and (min-width: 350px) {
  .header .branding {
    top: 120px;
  }
}
@media screen and (min-width: 768px) {
  .header .branding {
    width: 420px;
    top: 140px;
    margin-left: -210px;
  }
}
@media screen and (min-width: 1024px) {
  .header .branding {
    width: auto;
    margin-left: 0;
    top: initial;
    left: 30px;
  }
}
@media screen and (min-width: 1300px) {
  .header .branding {
    left: 12.5%;
  }
}
@media screen and (min-width: 1920px) {
  .header .branding {
    left: 12.5%;
  }
}
.header .branding .logo {
  display: block;
  padding: 15px;
  margin: 20px auto;
  line-height: 0;
  color: #fff;
  width: 50%;
}
@supports (display: grid) {
  .header .branding .logo {
    max-width: 150px;
  }
}
@media screen and (min-width: 768px) {
  @supports (display: grid) {
    .header .branding .logo {
      max-width: 200px;
    }
  }
}
@media screen and (min-width: 768px) and (min-height: 900px) {
  @supports (display: grid) {
    .header .branding .logo {
      max-width: 255px;
    }
  }
}
.header .branding .logo:focus {
  outline: 1px dotted #000;
}
.header nav {
  position: absolute;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .header nav {
    width: auto;
    right: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .header nav {
    right: 30px;
  }
}
@media screen and (min-width: 1300px) {
  .header nav {
    right: 12.5%;
  }
}
@media screen and (min-width: 1920px) {
  .header nav {
    right: 12.5%;
  }
}

.info {
  color: #696971;
}
@media screen and (min-width: 768px) {
  .info {
    padding: 0 32px 32px 32px;
  }
}
.info h2, .info .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .info h2.text-small {
  margin-top: 0;
  text-align: center;
}
.info h2 span, .info .sportmassan .module-sponsors h2.text-small span, .sportmassan .module-sponsors .info h2.text-small span {
  color: #000;
}
main .info {
  margin: 20px;
}
@media screen and (min-width: 768px) {
  main .info {
    display: none;
  }
}
main .info h2, main .info .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors main .info h2.text-small {
  color: #000;
  margin: 30px 0 15px 0;
}
@media screen and (min-width: 1024px) {
  .header .info {
    min-width: 330px;
  }
}
.header .info h2, .header .info .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .header .info h2.text-small {
  width: 100%;
  padding: 15px 20px 8px 20px;
  border-top: 1px solid #d8d8d8;
}
@media screen and (min-width: 768px) {
  .header .info h2, .header .info .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .header .info h2.text-small {
    border-bottom: 1px solid #d8d8d8;
  }
}
.header .info ul {
  display: none;
}
@media screen and (min-width: 768px) {
  .header .info ul {
    display: block;
    margin: 2em 24px;
    font-size: 0.95em;
  }
}

.cta {
  display: block;
  box-sizing: border-box;
  width: 60%;
  border: 0;
  padding: 13px 30px;
  background: #f47e3a;
  color: #fff;
  font-family: neuzeit-grotesk, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.1em;
  transition: all 0.3s;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.04), 0 8px 8px rgba(0, 0, 0, 0.04), 0 16px 16px rgba(0, 0, 0, 0.04);
}
.cta:hover, .cta:focus {
  cursor: pointer;
  background: #f36e22;
  color: #fff;
  transform: scale(1.05);
}
main .cta {
  margin: 10px 20% 20px 20%;
}
@media screen and (min-width: 768px) {
  main .cta {
    display: none;
  }
}
.header .cta {
  display: none;
}
@media screen and (min-width: 768px) {
  .header .cta {
    display: block;
    margin: 0 20%;
    font-size: 16px;
  }
}

.top-image {
  width: 100%;
  height: 340px;
  text-align: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 350px) {
  .top-image {
    height: 400px;
  }
}
@media screen and (min-width: 768px) {
  .top-image {
    height: 75vh;
  }
}
@media screen and (min-width: 1024px) {
  .top-image {
    height: 90vh;
    max-height: 900px;
  }
}
.top-image img {
  width: 50%;
  max-width: 100%;
  height: auto;
}
@media screen and (min-width: 768px) {
  .top-image img {
    width: 300px;
  }
}

.main-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  overflow-x: hidden;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.01), 0 8px 8px rgba(0, 0, 0, 0.01), 0 16px 16px rgba(0, 0, 0, 0.01);
}
.main-nav li {
  display: inline;
  width: 100%;
}
.main-nav li.highlighted a {
  background: #f47e3a;
  color: #fff;
}
.main-nav a {
  display: block;
  background: #fff;
  color: #000;
  padding: 15px 15px;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .main-nav a {
    padding: 15px 20px;
  }
}
@media screen and (min-width: 1024px) {
  .main-nav a {
    padding: 15px 40px;
  }
}
.main-nav a:hover {
  color: #000;
  text-decoration: underline;
}
.main-nav > li + li a {
  border-left: 1px solid #d8d8d8;
}

.floating-nav .main-nav {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03), 0 8px 8px rgba(0, 0, 0, 0.03), 0 16px 16px rgba(0, 0, 0, 0.03);
}
.floating-nav .branding {
  display: none !important;
}

.section {
  clear: both;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.section .section-content {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 0 9.6875%;
}
@media screen and (min-width: 768px) {
  .section .section-content {
    padding: 0 2.1484375%;
  }
}
@media screen and (min-width: 1024px) {
  .section .section-content {
    padding: 0 12.5%;
  }
}
@media screen and (min-width: 1920px) {
  .section .section-content {
    max-width: 1920px;
    padding: 0 240px;
  }
}
.section .no-heading {
  padding: 10px 31 px;
}
@media screen and (min-width: 768px) {
  .section .no-heading {
    padding: 10px 2.1484375%;
  }
}
@media screen and (min-width: 1024px) {
  .section .no-heading {
    padding: 10px 12.5%;
  }
}
@media screen and (min-width: 1920px) {
  .section .no-heading {
    padding: 10px 12.5%;
  }
}
.section.color-1 {
  background: #f47e3a;
  color: #fff;
  border-color: #fff;
}
.section.color-1 a {
  color: #fff;
}
.section.color-2 {
  background: #5383a2;
  color: #fff;
  border-color: #fff;
}
.section.color-2 a {
  color: #fff;
}
.section.color-3 {
  background: #696971;
  color: #fff;
  border-color: #fff;
}
.section.color-3 a {
  color: #fff;
}

main > .section {
  margin: 30px 0 30px 0;
}
@media screen and (min-width: 768px) {
  main > .section {
    margin: 80px 0;
  }
}
main > .section a:hover {
  color: #f47e3a;
}

main > .section:last-child {
  margin-bottom: 0;
}

main > .section-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}
@media screen and (min-width: 1024px) {
  main > .section-icons {
    justify-content: space-evenly;
  }
}
main > .section-icons .icon {
  flex: 1 0 0;
  min-width: 150px;
  min-height: 200px;
  padding: 30px 20px;
  color: #696971;
}
@media screen and (min-width: 350px) {
  main > .section-icons .icon:nth-child(1), main > .section-icons .icon:nth-child(2) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  main > .section-icons .icon:nth-child(odd) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}
@media screen and (min-width: 450px) {
  main > .section-icons .icon {
    border: 0;
  }
  main > .section-icons .icon:nth-child(1), main > .section-icons .icon:nth-child(2) {
    border-bottom: 0;
  }
  main > .section-icons .icon:nth-child(odd) {
    border: 0;
  }
}
@media screen and (min-width: 700px) {
  main > .section-icons .icon {
    margin: 20px 0;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  main > .section-icons .icon:nth-child(odd) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  main > .section-icons .icon:first-child {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
}
main > .section-icons .icon img {
  padding-bottom: 10px;
}
main > .section-icons .icon small {
  color: #000;
}

main > .section-main-introduction {
  margin: 0;
  padding: 0;
  background: #eee;
  color: #000;
  overflow: hidden;
}
main > .section-main-introduction .section-content {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
}
@media screen and (min-width: 768px) {
  main > .section-main-introduction .section-content {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  main > .section-main-introduction .section-content .text,
main > .section-main-introduction .section-content .image {
    float: left;
    width: 50%;
  }
  @supports (display: grid) {
    main > .section-main-introduction .section-content .text,
main > .section-main-introduction .section-content .image {
      width: auto;
    }
  }
}
main > .section-main-introduction .section-content .text {
  padding: 1em 10%;
}
@media screen and (min-width: 768px) {
  main > .section-main-introduction .section-content .text {
    padding: 1em 10.9183673469%;
    margin-top: 2em;
  }
  @supports (display: grid) {
    main > .section-main-introduction .section-content .text {
      margin-top: 0;
    }
  }
}
@media screen and (min-width: 1024px) {
  main > .section-main-introduction .section-content .text {
    padding: 1em 50px 1em 12.5%;
  }
  @supports (display: grid) {
    main > .section-main-introduction .section-content .text {
      padding: 1em 50px 1em 25.2777777778%;
    }
  }
}
@media screen and (min-width: 1920px) {
  main > .section-main-introduction .section-content .text {
    padding: 1em 100px 1em 240px;
  }
}
main > .section-main-introduction .section-content .text p {
  max-width: 70ch;
}
main > .section-main-introduction .section-content .image {
  display: none;
}
@media screen and (min-width: 768px) {
  main > .section-main-introduction .section-content .image {
    display: block;
  }
}
main > .section-main-introduction .section-content .image figure {
  line-height: 0;
}
@media screen and (min-width: 768px) {
  main > .section-main-introduction .section-content h1 {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  main > .section-main-introduction .section-content p {
    margin-bottom: 0;
  }
}

main > .section-longtext {
  padding-bottom: 10em;
}
@media screen and (min-width: 768px) {
  main > .section-longtext {
    margin: 8.6734693878%;
  }
}
@media screen and (min-width: 1024px) {
  main > .section-longtext {
    margin: 0 17.2222222222%;
  }
}
main > .section-longtext h2, main > .section-longtext .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors main > .section-longtext h2.text-small {
  margin-top: 2em;
}
main > .section-longtext h3 {
  text-transform: uppercase;
  margin-top: 2em;
}
main > .section-longtext ul {
  margin: 0 0 2em -10px;
  padding: 0;
}
main > .section-longtext li {
  margin: 0;
  padding: 0 0 0 10px;
}

.section-main-introduction figure,
.section.module-textandmedia picture {
  position: relative;
  overflow: hidden;
}
.section-main-introduction figure img,
.section.module-textandmedia picture img {
  transition: all 0.6s ease-out;
}
.section-main-introduction figure:hover img,
.section.module-textandmedia picture:hover img {
  transform: scale(1.1) rotateZ(-1deg);
}

footer {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 40px 9.6875%;
  margin: 0 auto;
  background: #222;
  color: #ccc;
  text-align: center;
  font-size: 0.8em;
}
footer p {
  margin: 0;
  padding: 0;
}
footer a {
  color: #fff;
}
footer a:hover {
  color: #f47e3a;
}
footer .social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1em 0;
}
footer .social-icons li {
  list-style-type: none;
}
footer .icon {
  display: block;
  width: 30px;
  height: 30px;
  margin: 8px;
}
footer .terms-and-conditions {
  margin: 2em 0;
  padding: 0;
}
footer .terms-and-conditions li {
  display: inline;
}
footer .terms-and-conditions a {
  display: inline-block;
  padding: 4px;
}

.fslightbox-slide-btn-container-next .fslightbox-slide-btn {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTAgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYxICg4OTU4MSkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+bGlnaHRib3gtYXJyb3ctcmlnaHQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0ibGlnaHRib3gtYXJyb3ctcmlnaHQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJsdmwyLWFycm93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjAwMDAwMCwgMS4wMDAwMDApIiBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aCIgcG9pbnRzPSItMi43NTMzNTMxZS0xNCAwIDcuNSA3LjUgLTIuNzUzMzUzMWUtMTQgMTUiPjwvcG9seWxpbmU+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
}

.fslightbox-slide-btn-container-previous .fslightbox-slide-btn {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTAgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYxICg4OTU4MSkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+bGlnaHRib3gtYXJyb3ctbGVmdDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJsaWdodGJveC1hcnJvdy1sZWZ0IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0ibHZsMi1hcnJvdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNS4wMDAwMDAsIDguNTAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC01LjAwMDAwMCwgLTguNTAwMDAwKSB0cmFuc2xhdGUoMS4wMDAwMDAsIDEuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8cG9seWxpbmUgaWQ9IlBhdGgiIHBvaW50cz0iLTIuNzUzMzUzMWUtMTQgMCA3LjUgNy41IC0yLjc1MzM1MzFlLTE0IDE1Ij48L3BvbHlsaW5lPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
}

.fslightbox-slide-btn {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 10px !important;
}
.fslightbox-slide-btn svg {
  visibility: hidden;
}

.fslightbox-slide-number-container {
  display: none !important;
}

.fslightbox-toolbar .fslightbox-toolbar-button:first-child {
  display: none;
}

.button,
.button-wrapper a {
  font-feature-settings: "pnum" 1, "lnum" 1;
  font-family: neuzeit-grotesk, sans-serif;
  font-size: 0.85em;
  border: 1px solid #f47e3a;
  background: #f47e3a;
  color: #fff;
  padding: 12px 28px 12px 28px;
  margin: 0 10px 15px 0;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 3px;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  line-height: 1.5;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03), 0 6px 8px rgba(0, 0, 0, 0.03), 0 8px 16px rgba(0, 0, 0, 0.03);
  /* Primary action button, i.e. 'Ok' */
  /* Secondary action button, i.e. 'Cancel' */
  /* White */
  /* Black */
  /* Large Call-to-Action */
  /* Large Secondary Call-to-Action */
}
@supports (font-variant-numeric: proportional-nums) {
  .button,
.button-wrapper a {
    font-feature-settings: normal;
    font-variant-numeric: proportional-nums lining-nums;
  }
}
.button:hover,
.button-wrapper a:hover {
  background-color: #f58e52;
  border: 1px solid #f58e52;
  color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11), 0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11), 0 8px 16px rgba(0, 0, 0, 0.11);
}
.button:active,
.button-wrapper a:active {
  background-color: #949494;
  border: 1px solid #949494;
  color: #fff;
}
.button:focus,
.button-wrapper a:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 130, 0, 0.5);
  text-decoration: underline;
}
.button.disabled, .button.disabled:hover,
.button-wrapper a.disabled,
.button-wrapper a.disabled:hover {
  background: #eee;
  border-color: #eee;
  color: #aaa;
  box-shadow: none;
}
.button.disabled .icon, .button.disabled:hover .icon,
.button-wrapper a.disabled .icon,
.button-wrapper a.disabled:hover .icon {
  opacity: 0.5;
}
.button .icon,
.button-wrapper a .icon {
  float: left;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: 100%;
  margin: -2px 7px 0 -4px;
}
.button--primary,
.button-wrapper a--primary {
  border: 1px solid #f47e3a;
  background: #f47e3a;
  color: #fff;
  min-width: 80px;
  text-align: center;
}
.button--primary:hover,
.button-wrapper a--primary:hover {
  background: #f58e52;
  border: 1px solid #f58e52;
  color: #fff;
}
.button--primary:active,
.button-wrapper a--primary:active {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 130, 0, 0.5);
  background: #f69761;
  border: 1px solid #f69761;
  color: #fff;
}
.button--primary.disabled, .button--primary.disabled:hover,
.button-wrapper a--primary.disabled,
.button-wrapper a--primary.disabled:hover {
  border: 1px solid gray;
  text-shadow: none;
}
.color-1 .button--primary, .color-2 .button--primary, .color-3 .button--primary, .color-4 .button--primary, .color-5 .button--primary,
.color-1 .button-wrapper a--primary,
.color-2 .button-wrapper a--primary,
.color-3 .button-wrapper a--primary,
.color-4 .button-wrapper a--primary,
.color-5 .button-wrapper a--primary {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
}
.color-1 .button--primary:hover, .color-2 .button--primary:hover, .color-3 .button--primary:hover, .color-4 .button--primary:hover, .color-5 .button--primary:hover,
.color-1 .button-wrapper a--primary:hover,
.color-2 .button-wrapper a--primary:hover,
.color-3 .button-wrapper a--primary:hover,
.color-4 .button-wrapper a--primary:hover,
.color-5 .button-wrapper a--primary:hover {
  border: 1px solid #fff;
  background: #fafafa;
  color: #000;
}
.button--secondary,
.button-wrapper a--secondary {
  border: 1px solid #f47e3a;
  background: transparent;
  color: #f47e3a;
  min-width: 80px;
  text-align: center;
  box-shadow: none;
}
.button--secondary:hover,
.button-wrapper a--secondary:hover {
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
}
.button--secondary:active,
.button-wrapper a--secondary:active {
  border: 1px solid #f26513;
}
.button--secondary.disabled, .button--secondary.disabled:hover,
.button-wrapper a--secondary.disabled,
.button-wrapper a--secondary.disabled:hover {
  box-shadow: none;
  border: 1px solid #b3b3b3;
}
.color-1 .button--secondary, .color-2 .button--secondary, .color-3 .button--secondary, .color-4 .button--secondary, .color-5 .button--secondary,
.color-1 .button-wrapper a--secondary,
.color-2 .button-wrapper a--secondary,
.color-3 .button-wrapper a--secondary,
.color-4 .button-wrapper a--secondary,
.color-5 .button-wrapper a--secondary {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.color-1 .button--secondary:hover, .color-2 .button--secondary:hover, .color-3 .button--secondary:hover, .color-4 .button--secondary:hover, .color-5 .button--secondary:hover,
.color-1 .button-wrapper a--secondary:hover,
.color-2 .button-wrapper a--secondary:hover,
.color-3 .button-wrapper a--secondary:hover,
.color-4 .button-wrapper a--secondary:hover,
.color-5 .button-wrapper a--secondary:hover {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
}
.color-1 .button--secondary.disabled, .color-1 .button--secondary.disabled:hover, .color-2 .button--secondary.disabled, .color-2 .button--secondary.disabled:hover, .color-3 .button--secondary.disabled, .color-3 .button--secondary.disabled:hover, .color-4 .button--secondary.disabled, .color-4 .button--secondary.disabled:hover, .color-5 .button--secondary.disabled, .color-5 .button--secondary.disabled:hover,
.color-1 .button-wrapper a--secondary.disabled,
.color-1 .button-wrapper a--secondary.disabled:hover,
.color-2 .button-wrapper a--secondary.disabled,
.color-2 .button-wrapper a--secondary.disabled:hover,
.color-3 .button-wrapper a--secondary.disabled,
.color-3 .button-wrapper a--secondary.disabled:hover,
.color-4 .button-wrapper a--secondary.disabled,
.color-4 .button-wrapper a--secondary.disabled:hover,
.color-5 .button-wrapper a--secondary.disabled,
.color-5 .button-wrapper a--secondary.disabled:hover {
  background: #eee;
  border-color: #eee;
  color: #aaa;
}
.button--white,
.button-wrapper a--white {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
}
.button--white:hover,
.button-wrapper a--white:hover {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
}
.button--white:active,
.button-wrapper a--white:active {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
}
.button--white.disabled:hover,
.button-wrapper a--white.disabled:hover {
  color: rgb(170, 170, 170);
}
.color-1 .button--white, .color-2 .button--white, .color-3 .button--white, .color-4 .button--white, .color-5 .button--white,
.color-1 .button-wrapper a--white,
.color-2 .button-wrapper a--white,
.color-3 .button-wrapper a--white,
.color-4 .button-wrapper a--white,
.color-5 .button-wrapper a--white {
  background: transparent;
  color: #fff;
}
.color-1 .button--white:hover, .color-2 .button--white:hover, .color-3 .button--white:hover, .color-4 .button--white:hover, .color-5 .button--white:hover,
.color-1 .button-wrapper a--white:hover,
.color-2 .button-wrapper a--white:hover,
.color-3 .button-wrapper a--white:hover,
.color-4 .button-wrapper a--white:hover,
.color-5 .button-wrapper a--white:hover {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
}
.color-1 .button--white.disabled, .color-1 .button--white.disabled:hover, .color-2 .button--white.disabled, .color-2 .button--white.disabled:hover, .color-3 .button--white.disabled, .color-3 .button--white.disabled:hover, .color-4 .button--white.disabled, .color-4 .button--white.disabled:hover, .color-5 .button--white.disabled, .color-5 .button--white.disabled:hover,
.color-1 .button-wrapper a--white.disabled,
.color-1 .button-wrapper a--white.disabled:hover,
.color-2 .button-wrapper a--white.disabled,
.color-2 .button-wrapper a--white.disabled:hover,
.color-3 .button-wrapper a--white.disabled,
.color-3 .button-wrapper a--white.disabled:hover,
.color-4 .button-wrapper a--white.disabled,
.color-4 .button-wrapper a--white.disabled:hover,
.color-5 .button-wrapper a--white.disabled,
.color-5 .button-wrapper a--white.disabled:hover {
  background: #eee;
  border-color: #eee;
  color: #aaa;
}
.button--black,
.button-wrapper a--black {
  border: 1px solid #000;
  background: #000;
  color: #fff;
}
.button--black:hover,
.button-wrapper a--black:hover {
  border: 1px solid #000;
  background: #000;
  color: #fff;
}
.button--black:active,
.button-wrapper a--black:active {
  border: 1px solid #000;
  background: #000;
  color: #fff;
}
.button--black.disabled, .button--black.disabled:hover,
.button-wrapper a--black.disabled,
.button-wrapper a--black.disabled:hover {
  color: gray;
}
.color-1 .button--black, .color-2 .button--black, .color-3 .button--black, .color-4 .button--black, .color-5 .button--black,
.color-1 .button-wrapper a--black,
.color-2 .button-wrapper a--black,
.color-3 .button-wrapper a--black,
.color-4 .button-wrapper a--black,
.color-5 .button-wrapper a--black {
  background: transparent;
  color: #000;
}
.color-1 .button--black:hover, .color-2 .button--black:hover, .color-3 .button--black:hover, .color-4 .button--black:hover, .color-5 .button--black:hover,
.color-1 .button-wrapper a--black:hover,
.color-2 .button-wrapper a--black:hover,
.color-3 .button-wrapper a--black:hover,
.color-4 .button-wrapper a--black:hover,
.color-5 .button-wrapper a--black:hover {
  border: 1px solid #000;
  background: #000;
  color: #fff;
}
.color-1 .button--black.disabled, .color-1 .button--black.disabled:hover, .color-2 .button--black.disabled, .color-2 .button--black.disabled:hover, .color-3 .button--black.disabled, .color-3 .button--black.disabled:hover, .color-4 .button--black.disabled, .color-4 .button--black.disabled:hover, .color-5 .button--black.disabled, .color-5 .button--black.disabled:hover,
.color-1 .button-wrapper a--black.disabled,
.color-1 .button-wrapper a--black.disabled:hover,
.color-2 .button-wrapper a--black.disabled,
.color-2 .button-wrapper a--black.disabled:hover,
.color-3 .button-wrapper a--black.disabled,
.color-3 .button-wrapper a--black.disabled:hover,
.color-4 .button-wrapper a--black.disabled,
.color-4 .button-wrapper a--black.disabled:hover,
.color-5 .button-wrapper a--black.disabled,
.color-5 .button-wrapper a--black.disabled:hover {
  background: #eee;
  border-color: #eee;
  color: #aaa;
}
.button--large,
.button-wrapper a--large {
  font-size: 1.2rem;
  padding: 10px 28px;
  border: 0;
  background-color: #d66d00;
  background-image: linear-gradient(180deg, #ff8200 0%, #d66d00 100%);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
.button--large:hover,
.button-wrapper a--large:hover {
  border: 0;
  transform: scale(1.05);
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
}
.button--large.disabled:hover,
.button-wrapper a--large.disabled:hover {
  transform: scale(1);
  box-shadow: none;
}
.button--large--secondary,
.button-wrapper a--large--secondary {
  font-size: 1.2rem;
  padding: 10px 28px;
  border: 0;
  background-color: #efefef;
  background-image: linear-gradient(0deg, #efefef 0%, #fff 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  color: #000;
}
.button--large--secondary:hover,
.button-wrapper a--large--secondary:hover {
  border: 0;
  transform: scale(1.05);
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
  color: #000;
}
.button--large--secondary:active,
.button-wrapper a--large--secondary:active {
  color: #000;
}
.button--large--secondary.disabled:hover,
.button-wrapper a--large--secondary.disabled:hover {
  transform: scale(1);
  box-shadow: none;
}

a.button {
  display: inline-block;
}

.module-ad .section-content {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px 0.6451612903%;
}
@media screen and (min-width: 768px) {
  .module-ad .section-content {
    grid-gap: 30px 4.0816326531%;
  }
}
@media screen and (min-width: 1024px) {
  .module-ad .section-content {
    grid-gap: 30px 3.3333333333%;
  }
}
@media screen and (min-width: 1920px) {
  .module-ad .section-content {
    grid-gap: 30px 3.3333333333%;
  }
}
@media screen and (min-width: 768px) {
  .module-ad .section-content {
    grid-template-columns: 1fr 1fr;
  }
}
.module-ad .section-content:after {
  content: "&nbsp;";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@supports (display: grid) {
  .module-ad .section-content:after {
    clear: initial;
    display: initial;
    visibility: initial;
    height: initial;
    content: "";
  }
}
.module-ad .section-content figure {
  text-align: center;
}
.module-ad .section-content figure img {
  width: 100%;
}
.module-ad .section-content .width-50 {
  float: left;
  width: 45%;
  margin: 0 2.5%;
}
@supports (display: grid) {
  .module-ad .section-content .width-50 {
    float: none;
    width: auto;
    margin: 0;
    grid-column: span 1;
  }
}
.module-ad .section-content .width-100 {
  grid-column: span 1;
}
@media screen and (min-width: 768px) {
  .module-ad .section-content .width-100 {
    grid-column: span 2;
  }
}
.module-ad .section-content figure:first-child::before {
  content: "Annons";
  color: #808080;
  font-size: 0.85em;
  position: absolute;
  top: -30px;
  right: 1.5625%;
}
@media screen and (min-width: 768px) {
  .module-ad .section-content figure:first-child::before {
    right: 4.345703125%;
  }
  @supports (display: grid) {
    .module-ad .section-content figure:first-child::before {
      right: 1.5625%;
    }
  }
}
@media screen and (min-width: 1024px) {
  .module-ad .section-content figure:first-child::before {
    right: 14.4791666667%;
  }
  @supports (display: grid) {
    .module-ad .section-content figure:first-child::before {
      right: 1.5625%;
    }
  }
}
@media screen and (min-width: 1920px) {
  .module-ad .section-content figure:first-child::before {
    right: 14.4791666667%;
  }
  @supports (display: grid) {
    .module-ad .section-content figure:first-child::before {
      right: 1.5625%;
    }
  }
}

.module-card .section-content > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.module-card .card + .card {
  margin-top: 1.5rem;
}
@supports (display: grid) {
  .module-card .section-content > ul {
    margin: 0;
    padding: 0;
  }
  @media screen and (min-width: 768px) {
    .module-card .section-content > ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 1.5rem;
      grid-row-gap: 1.5rem;
    }
  }
  @media screen and (min-width: 1100px) {
    .module-card .section-content > ul {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  .module-card .card + .card {
    margin-top: 0;
  }
}
.module-card .card {
  background: #fff;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.01), 0 8px 8px rgba(0, 0, 0, 0.01), 0 16px 16px rgba(0, 0, 0, 0.01);
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 768px) {
  .module-card .card {
    margin-bottom: 0;
  }
}
.module-card .card::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03), 0 8px 8px rgba(0, 0, 0, 0.03), 0 16px 16px rgba(0, 0, 0, 0.03);
  transition: opacity 0.5s ease-in-out;
}
.module-card .card:hover::after {
  opacity: 1;
}
.module-card .card:focus {
  border: 1px solid red;
}
.module-card .card .img {
  border-radius: 0.25rem 0.25rem 0 0;
}
.module-card .card .img.color-1 h3 *, .module-card .card .img.color-2 h3 *, .module-card .card .img.color-3 h3 * {
  color: inherit;
}
.module-card .card .img img {
  border-radius: 0.25rem 0.25rem 0 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.module-card .card figure,
.module-card .card picture {
  line-height: 0.01;
}
.module-card .card figure.dynamic-height,
.module-card .card picture.dynamic-height {
  height: 100%;
}
.module-card .card .text {
  padding: 1rem 20px 1.5rem 20px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .module-card .card .text {
    padding: 1rem 40px 1.5rem 40px;
  }
}
.module-card .card .text > p + p {
  margin-top: -0.75rem;
}
.module-card .card .text p:last-child {
  margin-bottom: 0;
}
.module-card .card .text .button {
  margin-top: 0.5rem;
  margin-bottom: 0.5em;
}
.module-card .card h3 {
  font-size: 20px;
}
@media screen and (min-width: 768px) {
  .module-card .card h3 {
    font-size: 28px;
  }
}
.module-card .card h3 a {
  color: #000;
}
.module-card .card h3 a:hover {
  text-decoration: underline;
}
.module-card .card h3 a:focus {
  text-decoration: underline;
}
.module-card .card h3 span {
  color: #6e6259;
}
.module-card .card p {
  max-width: 60ch;
}
@media screen and (min-width: 768px) {
  .module-card .card p {
    display: unset;
  }
}
.module-card .card a {
  text-decoration: underline;
}
.module-card .card a.button {
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .module-card .card--width-33 {
    float: left;
    width: 45%;
    margin: 0 2.5%;
  }
  @supports (display: grid) {
    .module-card .card--width-33 {
      float: none;
      width: auto;
      min-height: unset;
      margin: 0;
      grid-column: span 1;
    }
  }
  .module-card .card--width-66 {
    float: left;
    width: 95%;
    margin: 0 2.5%;
  }
  @supports (display: grid) {
    .module-card .card--width-66 {
      float: none;
      width: auto;
      min-height: unset;
      margin: 0;
      grid-column: span 2;
    }
  }
  .module-card .card--width-100 {
    grid-column: span 2;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .module-card .card--width-100 {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .module-card .card--width-100 .img {
    height: auto !important;
    width: 50%;
  }
  @supports (display: grid) {
    .module-card .card--width-100 .img {
      float: none;
      width: auto;
    }
  }
}
@media screen and (min-width: 768px) {
  .module-card .card--width-100 .img img {
    border-radius: 0.25rem 0 0 0.25rem;
    max-width: 700px;
  }
}
@media screen and (min-width: 768px) {
  .module-card .card--width-100 .text {
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    box-sizing: border-box;
  }
  @supports (display: grid) {
    .module-card .card--width-100 .text {
      float: none;
      width: auto;
    }
  }
  .module-card .card--width-100 .text h3 {
    max-width: 100%;
  }
}
@media screen and (min-width: 1100px) {
  .module-card .card--width-33 {
    float: left;
    width: 28%;
    margin: 0 2.5%;
  }
  @supports (display: grid) {
    .module-card .card--width-33 {
      float: none;
      width: auto;
      margin: 0;
      grid-column: span 1;
    }
  }
  .module-card .card--width-100 {
    grid-column: span 3;
  }
}

.module-scripts .section-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 768px) {
  .module-course .text {
    text-align: center;
  }
}

.module-events .section-content {
  padding-top: 20px;
  padding-bottom: 20px;
  /*
  .events::-webkit-scrollbar {
      display: none;
      inline-size: 0 !important;
  }
  */
}
@media screen and (min-width: 768px) {
  .module-events .section-content {
    background: #fff;
    background: unset;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .module-events .section-content .box {
    background: #fff;
    padding: 40px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.01), 0 8px 8px rgba(0, 0, 0, 0.01), 0 16px 16px rgba(0, 0, 0, 0.01);
  }
}
.module-events .section-content h2, .module-events .section-content .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .module-events .section-content h2.text-small {
  max-width: 15ch;
}
.module-events .section-content a {
  color: #000;
  text-decoration: none;
}
.module-events .section-content .text {
  max-width: 60ch;
  margin: 2rem 0 3rem 0;
}
.module-events .section-content .events {
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
  scrollbar-width: none;
}
@supports (display: grid) {
  .module-events .section-content .events {
    overflow-x: scroll;
    display: grid;
    grid-template-columns: 200px;
    grid-auto-columns: 200px;
    grid-auto-flow: column;
    grid-gap: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .module-events .section-content .events {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: unset;
    grid-template-rows: auto;
    grid-gap: 40px;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .module-events .section-content .events {
    grid-gap: 40px 3.3333333333%;
    grid-template-columns: repeat(4, 1fr);
  }
}
.module-events .section-content .items::-webkit-scrollbar {
  display: unset;
  width: 5px;
  height: 8px;
  background-color: transparent;
}
.module-events .section-content .items::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.module-events .section-content .event {
  background: #fff;
  position: relative;
  scroll-snap-align: start;
  float: left;
  width: 45%;
  min-height: 370px;
  margin: 0 2.5%;
}
@supports (display: grid) {
  .module-events .section-content .event {
    float: none;
    width: auto;
    min-height: unset;
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .module-events .section-content .event {
    background: none;
    float: left;
    width: 28.3333%;
    min-height: 420px;
    margin: 0 2.5%;
  }
  @supports (display: grid) {
    .module-events .section-content .event {
      float: none;
      width: auto;
      min-height: unset;
      margin: 0;
    }
  }
}
@media screen and (min-width: 1024px) {
  .module-events .section-content .event {
    float: left;
    width: 20%;
    min-height: 420px;
    margin: 0 2.5%;
  }
  @supports (display: grid) {
    .module-events .section-content .event {
      float: none;
      width: auto;
      min-height: unset;
      margin: 0;
    }
  }
  .module-events .section-content .event:hover h3 a {
    text-decoration: underline;
  }
}
.module-events .section-content .event .event-text {
  padding: 5px 15px;
}
@media screen and (min-width: 768px) {
  .module-events .section-content .event .event-text {
    padding: 0;
  }
}
.module-events .section-content .event .date {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 36px;
  background: #e4002b;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 1.7;
  font-weight: 500;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .module-events .section-content .event .date {
    font-size: 12px;
    min-width: 48px;
  }
}
.module-events .section-content .event .date--day {
  width: 100%;
  background: #fff;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
}
@media screen and (min-width: 768px) {
  .module-events .section-content .event .date--day {
    font-size: 20px;
  }
}
.module-events .section-content .event .date--month {
  text-transform: uppercase;
}
.module-events .section-content .event .date--text {
  padding: 0 10px;
}
.module-events .section-content .event figure,
.module-events .section-content .event picture {
  display: block;
  line-height: 0.01;
}
.module-events .section-content .event figure img,
.module-events .section-content .event picture img {
  width: 100%;
}
.module-events .section-content .event .svg {
  background: #eee;
  width: 100%;
  padding-bottom: 75%;
}
.module-events .section-content .event .svg picture {
  position: relative;
}
.module-events .section-content .event .svg img {
  position: absolute;
  width: 70%;
  padding-top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 768px) {
  .module-faq .text {
    text-align: center;
  }
}
.module-faq .faqs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .module-faq .faqs {
    margin: 3em 5% 0 5%;
  }
}
.module-faq .faq {
  width: 100%;
  margin: 0 0 2em 0;
}
.module-faq .faq.large {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 45px;
}
@media screen and (min-width: 1200px) {
  .module-faq .faq.large {
    width: 50%;
    padding-right: 50px;
    margin-bottom: 60px;
  }
}
.module-faq .faq img {
  float: left;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 20px;
  flex: none;
}
@media screen and (min-width: 600px) {
  .module-faq .faq img {
    width: 100px;
    height: 100px;
    margin-right: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .module-faq .faq img {
    width: 175px;
    height: 175px;
  }
}
@media screen and (min-width: 1200px) {
  .module-faq .faq img {
    width: 100px;
    height: 100px;
  }
}
@media screen and (min-width: 1400px) {
  .module-faq .faq img {
    width: 175px;
    height: 175px;
  }
}
.module-faq .faq .qa {
  flex: 0 1 auto;
}
.module-faq .faq .qa .question {
  font-weight: bold;
}

.module-faq-list {
  background: #fff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.01), 0px 2px 2px 0px rgba(0, 0, 0, 0.01), 0px 4px 4px 0px rgba(0, 0, 0, 0.01), 0px 8px 8px 0px rgba(0, 0, 0, 0.01), 0px 16px 16px 0px rgba(0, 0, 0, 0.01);
}
.module-faq-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.module-faq-list li {
  margin: 0;
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.module-faq-list li:last-child {
  border: 0;
}
.module-faq-list a {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .module-faq-list a {
    font-size: 28px;
    line-height: 120%; /* 33.6px */
  }
}
.module-faq-list a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .module-gallery .text {
    text-align: center;
  }
}
.module-gallery .images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
}
@media screen and (min-width: 600px) {
  .module-gallery .images {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .module-gallery .images {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
.module-gallery figure {
  float: left;
  width: 18%;
  margin-right: 2%;
}
@supports (display: grid) {
  .module-gallery figure {
    width: auto;
    margin-right: 0;
  }
}
.module-gallery img {
  width: 100%;
  height: auto;
  border: 1px solid #eee;
  padding: 3px;
}
@media screen and (min-width: 768px) {
  .module-gallery .large {
    grid-column: span 2;
    grid-row: span 2;
  }
}

@media screen and (min-width: 1024px) {
  .module-heading h2, .module-heading .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .module-heading h2.text-small {
    max-width: 30ch;
  }
}
@media screen and (min-width: 1024px) {
  .module-heading p {
    max-width: 60ch;
  }
}

.module-hr.section {
  margin: 50px 0;
}
@media screen and (min-width: 768px) {
  .module-hr.section {
    margin: 50px 200px;
  }
}
@media screen and (min-width: 1024px) {
  .module-hr.section {
    margin: 150px 0;
  }
}
.module-hr.color-grey hr {
  border-color: #d8d8d8;
}
.module-hr.color-1 {
  background: none;
}
.module-hr.color-1 hr {
  border-color: #f47e3a;
}

.module-image img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 768px) {
  .module-map .text {
    text-align: center;
  }
}
.module-map iframe {
  height: 600px;
  width: 100%;
}

.module-news .section-content {
  margin: 0 auto;
  padding: 0 8px;
}
@media screen and (min-width: 768px) {
  .module-news .section-content {
    padding: 0 2.1484375%;
  }
}
@media screen and (min-width: 1250px) {
  .module-news .section-content {
    padding: 0 12.5%;
  }
}
@media screen and (min-width: 1920px) {
  .module-news .section-content {
    max-width: 1920px;
    padding: 0 240px;
  }
}
.module-news .section-content h2, .module-news .section-content .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .module-news .section-content h2.text-small {
  margin-left: 1.5625%;
}
@media screen and (min-width: 768px) {
  .module-news .section-content h2, .module-news .section-content .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .module-news .section-content h2.text-small {
    margin-left: 0;
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 1250px) {
  .module-news .section-content .news {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 1em 3.3333333333%;
  }
  .module-news .section-content .news li:nth-child(2) {
    margin-top: 75px;
  }
  .module-news .section-content .news li:nth-child(5) {
    margin-bottom: 50px;
  }
}
.module-news .text-subheading {
  color: #6e6259;
}

.news {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .news {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.01), 0 8px 8px rgba(0, 0, 0, 0.01), 0 16px 16px rgba(0, 0, 0, 0.01);
  }
}

.newsitem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0.5em 0;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.01), 0 8px 8px rgba(0, 0, 0, 0.01), 0 16px 16px rgba(0, 0, 0, 0.01);
}
@media screen and (min-width: 768px) {
  .newsitem {
    background: none;
    box-shadow: none;
  }
}
@media screen and (min-width: 1250px) {
  .newsitem {
    flex-direction: row;
    margin: 0;
  }
}
.newsitem:hover h3 a {
  text-decoration: underline;
}
.newsitem .text {
  order: 2;
}
@media screen and (min-width: 768px) {
  .newsitem .text {
    order: unset;
  }
}
.newsitem .image {
  order: 1;
}
@media screen and (min-width: 768px) {
  .newsitem .image {
    order: unset;
  }
}
.newsitem img {
  flex: 0 0 auto;
  max-width: 100%;
}
.newsitem a {
  color: #000;
  text-decoration: none;
}
.newsitem a:hover {
  text-decoration: underline;
}
.newsitem--highlight .text {
  margin: 0 10px 10px 10px;
}
.newsitem--highlight .image {
  margin: 10px 10px 0 10px;
}
@media screen and (min-width: 768px) {
  .newsitem--highlight .image {
    margin: 10px;
  }
}
@media screen and (min-width: 768px) {
  .newsitem--highlight {
    grid-column: span 12;
    flex-direction: row;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  .newsitem--highlight .text {
    width: 39.2857142857%;
    margin: 0 4.5918367347%;
    padding: 100px 0;
  }
  .newsitem--highlight .image {
    width: 43.3673469388%;
    line-height: 0.01;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newsitem--highlight img {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 1250px) {
  .newsitem--highlight {
    grid-column: span 8;
    grid-row: span 4;
    border: 0;
  }
  .newsitem--highlight .text {
    width: 44.4915254237%;
    margin: 0 4.0254237288%;
    padding: 100px 0;
  }
  .newsitem--highlight .image {
    width: 47.4576271186%;
    margin: 10px;
    line-height: 0.01;
    align-self: stretch;
  }
}
.newsitem--list {
  flex-direction: row-reverse;
  justify-content: flex-end;
  border: none;
}
@media screen and (min-width: 768px) {
  .newsitem--list {
    grid-column: 2/12;
    margin: 0 4.5918367347%;
    padding-top: 1em;
  }
}
@media screen and (min-width: 1250px) {
  .newsitem--list {
    grid-column: span 4;
    margin: 0;
    padding: 0;
  }
}
.newsitem--list h3 {
  padding-top: 0;
  margin-right: 20px;
}
.newsitem--list h3 span {
  color: #696971;
  margin: 0 0 0.5em 0;
}
.newsitem--list .image {
  line-height: 0.01;
  margin: 10px 20px 10px 10px;
}
@media screen and (min-width: 1250px) {
  .newsitem--list .image {
    margin: 0 20px 0 0;
  }
}
.newsitem--list img {
  flex: 0 0 auto;
  max-width: 120px;
}
.newsitem--readmore {
  background: none;
  box-shadow: none;
}
@media screen and (min-width: 768px) {
  .newsitem--readmore {
    padding-bottom: 1em;
  }
}
.newsitem--readmore a {
  display: block;
  width: 100%;
  text-align: center;
  margin: 10px 0;
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .newsitem--readmore a {
    text-align: left;
  }
}

.module-partnertexts {
  margin: 0;
}
.module-partnertexts .partner {
  margin: 80px 0 80px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 0;
  justify-content: center;
  overflow: hidden;
  padding-top: 1em;
  padding-bottom: 1em;
}
@supports (display: grid) {
  .module-partnertexts .partner {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .module-partnertexts .partner {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    align-items: center;
  }
}
.module-partnertexts .text {
  padding-left: 50px;
}
@supports (display: grid) {
  .module-partnertexts .text {
    padding-left: 0;
  }
}
.module-partnertexts .text,
.module-partnertexts .media {
  float: left;
  width: 50%;
}
@supports (display: grid) {
  .module-partnertexts .text,
.module-partnertexts .media {
    width: auto;
  }
}
@media screen and (min-width: 768px) {
  .module-partnertexts .media--right .text,
.module-partnertexts .media--right .media {
    float: right;
    width: 50%;
  }
  @supports (display: grid) {
    .module-partnertexts .media--right .text,
.module-partnertexts .media--right .media {
      width: auto;
    }
  }
  .module-partnertexts .media--right .text {
    grid-column: 1;
    grid-row: 1;
    padding-right: 50px;
  }
  @supports (display: grid) {
    .module-partnertexts .media--right .text {
      padding-right: 0;
    }
  }
  .module-partnertexts .media--right .media {
    grid-column: 2;
  }
}
.module-partnertexts figure {
  display: flex;
  justify-content: center;
  line-height: 0.01;
}
.module-partnertexts figure img {
  width: 100%;
}
.module-partnertexts h2, .module-partnertexts .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .module-partnertexts h2.text-small {
  margin-top: 1rem;
  max-width: 30ch;
}
@media screen and (min-width: 768px) {
  .module-partnertexts h2, .module-partnertexts .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .module-partnertexts h2.text-small {
    margin-top: 0;
    padding-top: 0;
  }
}
.module-partnertexts p {
  max-width: 60ch;
}
.module-partnertexts .media + .text {
  margin-top: 2rem;
}
@media screen and (min-width: 768px) {
  .module-partnertexts .media + .text {
    margin-top: 0;
  }
}

.external .module-partnertexts figure {
  border: 1px solid #eee;
  padding: 3px;
}

.module-scroll-info {
  --content-max-width: 1400px;
  --space: 10px;
}
@media screen and (min-width: 500px) {
  .module-scroll-info {
    --space: 20px;
  }
}
.module-scroll-info .scroll-info-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: scroll;
  padding-bottom: 20px;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
.module-scroll-info .scroll-info-wrapper::before, .module-scroll-info .scroll-info-wrapper::after {
  content: "";
  align-self: stretch;
}
.module-scroll-info .scroll-info-wrapper::before {
  padding-inline-end: 0;
}
@media screen and (min-width: 500px) {
  .module-scroll-info .scroll-info-wrapper::before {
    padding-inline-start: max(var(--space), (100vw - var(--content-max-width)) / 2 - var(--space));
  }
}
.module-scroll-info .scroll-info-wrapper::after {
  padding-inline-end: 5px;
}
@media screen and (min-width: 500px) {
  .module-scroll-info .scroll-info-wrapper::after {
    padding-inline-end: max(var(--space), (100vw - var(--content-max-width)) / 2 - var(--space));
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .module-scroll-info .scroll-info-wrapper {
    -webkit-overflow-scrolling: touch;
  }
}
.module-scroll-info .scroll-info-wrapper::-webkit-scrollbar {
  display: unset;
  width: 5px;
  height: 8px;
  background-color: transparent;
}
.module-scroll-info .scroll-info-wrapper::-webkit-scrollbar {
  display: none;
  inline-size: 0 !important;
}
.module-scroll-info .scroll-info-wrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.module-scroll-info .scroll-info {
  display: block;
  flex-shrink: 0;
  width: 355px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  scroll-snap-align: center;
}
@media screen and (min-width: 500px) {
  .module-scroll-info .scroll-info {
    width: 375px;
  }
}
.module-scroll-info .scroll-info .text {
  padding: 20px 40px 40px 40px;
}
.module-scroll-info .scroll-info .text small {
  display: block;
  margin-bottom: 16px;
  color: #6E6259;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.module-scroll-info .scroll-info .text h2 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
}
.module-scroll-info .scroll-info .text .info-excerpt p {
  font-weight: 900;
}
.module-scroll-info .level {
  display: flex;
  justify-content: center;
}
.module-scroll-info .level button {
  background: none;
  border: none;
  box-shadow: none;
  color: #000;
  width: 50px;
  margin: 0;
  padding: 0;
}
.module-scroll-info .level button[disabled] {
  opacity: 0.2;
}

.section.module-short-info {
  background-color: #fff;
  margin-top: 0;
  padding-top: 30px;
}
.section.module-short-info .mobile-top-content {
  position: relative;
}
.section.module-short-info .mobile-top-content::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 666;
  height: 100%;
  width: 100px;
  background: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0)), to(#ffffff));
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, #ffffff);
  pointer-events: none;
}
.section.module-short-info .info-container {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
}
.section.module-short-info .short-info-overview {
  position: relative;
  height: 190px;
  max-width: 100%;
}
.section.module-short-info .short-info-overview::after {
  content: "";
  display: block;
  background: #808080;
  bottom: 20%;
  left: unset;
  width: inherit;
  height: 4px;
  position: absolute;
}
.section.module-short-info button.short-info-tab {
  border-radius: 50%;
  max-height: 20px;
  max-width: 20px;
  padding: 0;
  margin: 0;
  background: #808080;
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 1;
  border: 2px solid #F6F4F2;
  cursor: pointer;
  bottom: calc(20% - 8px);
  display: flex;
  align-items: flex-end;
  transform: rotate(-45deg);
}
.section.module-short-info button.short-info-tab.pulse-animation {
  animation-name: pulse;
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.section.module-short-info button.short-info-tab.selected {
  background-color: #AB1C46;
}
.section.module-short-info button.short-info-tab.selected span.tab-title {
  color: #AB1C46;
}
.section.module-short-info button.short-info-tab span.tab-title {
  padding-left: 26px;
  font-family: neuzeit-grotesk, sans-serif;
  font-weight: 600;
  font-size: 16px;
  left: 0px;
  bottom: 48px;
  white-space: nowrap;
  color: #f47e3a;
}
@keyframes pulse {
  0% {
    background-color: #f47e3a;
  }
}
.section.module-short-info .short-infos {
  position: relative;
  padding-bottom: 50px;
}
.section.module-short-info .short-infos #short-info-select {
  position: absolute;
  width: 150px;
  bottom: 0;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  left: calc(50% - 75px);
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .section.module-short-info .short-infos #short-info-select {
    right: 40px;
    top: 30px;
    left: unset;
    bottom: unset;
  }
}
.section.module-short-info .short-info {
  background-color: #fff;
  padding: 0 10px;
}
@media screen and (min-width: 768px) {
  .section.module-short-info .short-info {
    padding-top: 50px;
  }
}
.section.module-short-info .short-info.hidden {
  display: none;
}
.section.module-short-info .short-info .info-title {
  min-height: 40px;
}
.section.module-short-info .short-info .info-title.hide-on-mobile {
  display: none;
}
@media screen and (min-width: 768px) {
  .section.module-short-info .short-info .info-title.hide-on-mobile {
    display: block;
  }
  .section.module-short-info .short-info .info-title.hide-on-desktop {
    display: none;
  }
  .section.module-short-info .short-info .info-title.hide-on-desktop.hide-on-mobile {
    display: none;
  }
}
.section.module-short-info .short-info .text-and-image .info-image {
  display: block;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .section.module-short-info .short-info .text-and-image .info-image {
    float: right;
    max-width: calc(50% - 50px);
    margin: 0 0 40px 50px;
  }
}
.section.module-short-info .short-info .text-and-image .info-bottom-image img {
  width: 100%;
  height: auto;
}

.module-sponsored-content .section-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: #f47e3a;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
@media screen and (min-width: 768px) {
  .module-sponsored-content .section-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 580px;
  }
}
.module-sponsored-content h2, .module-sponsored-content .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .module-sponsored-content h2.text-small {
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .module-sponsored-content h2, .module-sponsored-content .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .module-sponsored-content h2.text-small {
    max-width: 20ch;
  }
}
.module-sponsored-content a {
  color: #fff;
}
.module-sponsored-content figure {
  max-width: 50px;
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .module-sponsored-content figure {
    max-width: 200px;
    margin: 0;
    align-self: flex-end;
  }
}

.module-sponsors {
  position: relative;
  padding: 0;
}
.goteborgsvarvet .module-sponsors .groups {
  padding-bottom: 100px;
}
.goteborgsvarvet .module-sponsors .group {
  position: relative;
  padding: 30px 0 60px 0;
  margin: 0 30px;
}
@media screen and (min-width: 768px) {
  .goteborgsvarvet .module-sponsors .group {
    padding: 30px 0;
  }
  @supports (display: grid) {
    .goteborgsvarvet .module-sponsors .group {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 35px;
    }
  }
}
@media screen and (min-width: 1024px) {
  @supports (display: grid) {
    .goteborgsvarvet .module-sponsors .group {
      grid-gap: 70px;
    }
  }
}
.goteborgsvarvet .module-sponsors h2, .goteborgsvarvet .sportmassan .module-sponsors h2.text-small, .sportmassan .goteborgsvarvet .module-sponsors h2.text-small {
  text-align: center;
  margin-bottom: 35px;
}
@media screen and (min-width: 768px) {
  .goteborgsvarvet .module-sponsors h2, .goteborgsvarvet .sportmassan .module-sponsors h2.text-small, .sportmassan .goteborgsvarvet .module-sponsors h2.text-small {
    position: absolute;
    left: -120px;
    top: 110px;
    width: 200px;
    padding-bottom: 15px;
    margin-bottom: 50px;
    border-bottom: 1px solid #ddd;
    transform: rotate(-90deg);
  }
}
@media screen and (min-width: 1024px) {
  .goteborgsvarvet .module-sponsors h2, .goteborgsvarvet .sportmassan .module-sponsors h2.text-small, .sportmassan .goteborgsvarvet .module-sponsors h2.text-small {
    left: -140px;
    top: 120px;
  }
}
@media screen and (min-width: 1920px) {
  .goteborgsvarvet .module-sponsors h2, .goteborgsvarvet .sportmassan .module-sponsors h2.text-small, .sportmassan .goteborgsvarvet .module-sponsors h2.text-small {
    left: -150px;
    top: 110px;
  }
}
.goteborgsvarvet .module-sponsors .logotypes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@supports (display: grid) {
  .goteborgsvarvet .module-sponsors .logotypes--large {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 70px;
  }
  @media screen and (min-width: 600px) {
    .goteborgsvarvet .module-sponsors .logotypes--large {
      grid-gap: 70px 140px;
    }
  }
  @media screen and (min-width: 768px) {
    .goteborgsvarvet .module-sponsors .logotypes--large {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 100px;
    }
  }
  @media screen and (min-width: 1350px) {
    .goteborgsvarvet .module-sponsors .logotypes--large {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 100px 140px;
    }
  }
}
@supports (display: grid) {
  .goteborgsvarvet .module-sponsors .logotypes--medium {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 35px;
  }
  @media screen and (min-width: 600px) {
    .goteborgsvarvet .module-sponsors .logotypes--medium {
      grid-gap: 70px;
    }
  }
  @media screen and (min-width: 1024px) {
    .goteborgsvarvet .module-sponsors .logotypes--medium {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 70px;
    }
  }
  @media screen and (min-width: 1350px) {
    .goteborgsvarvet .module-sponsors .logotypes--medium {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 70px;
    }
  }
}
@supports (display: grid) {
  .goteborgsvarvet .module-sponsors .logotypes--small {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 35px;
  }
  @media screen and (min-width: 600px) {
    .goteborgsvarvet .module-sponsors .logotypes--small {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 50px 100px;
    }
  }
  @media screen and (min-width: 1024px) {
    .goteborgsvarvet .module-sponsors .logotypes--small {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 50px 100px;
    }
  }
  @media screen and (min-width: 1350px) {
    .goteborgsvarvet .module-sponsors .logotypes--small {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
  @media screen and (min-width: 1920px) {
    .goteborgsvarvet .module-sponsors .logotypes--small {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
}
@media screen and (min-width: 768px) {
  .goteborgsvarvet .module-sponsors .logotypes {
    margin-left: 50px;
    min-height: 200px;
  }
}
.goteborgsvarvet .module-sponsors .logotypes > * {
  align-self: center;
}
.goteborgsvarvet .module-sponsors .logotypes a {
  float: left;
  width: 15%;
  margin: 0 2.5%;
}
@supports (display: flex) {
  .goteborgsvarvet .module-sponsors .logotypes a {
    float: none;
    width: auto;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.goteborgsvarvet .module-sponsors .logotypes a figure {
  float: none;
  width: auto;
  margin: 0;
}
.external .module-sponsors h2, .external .sportmassan .module-sponsors h2.text-small, .sportmassan .external .module-sponsors h2.text-small {
  align-self: center;
  display: inline-block;
  text-align: center;
  color: #808080;
  border-bottom: 1px solid #808080;
  margin-bottom: 1em;
}
.external .module-sponsors .groups {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2em 0 5em 0;
}
.external .module-sponsors .group {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}
@media screen and (min-width: 1024px) {
  .external .module-sponsors .group {
    padding: 30px;
  }
}
.external .module-sponsors .logotypes {
  display: flex;
  align-items: center;
  height: 100px;
}
@media screen and (min-width: 768px) {
  .external .module-sponsors .logotypes {
    height: 200px;
  }
}
.external .module-sponsors figure {
  max-width: 100px;
  margin: 10px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .external .module-sponsors figure {
    max-width: 150px;
    margin: 0 20px;
  }
}
.external .module-sponsors .divider {
  display: none;
}
@media screen and (min-width: 768px) {
  .external .module-sponsors .divider {
    display: unset;
    margin-top: 100px;
    width: 1px;
    height: 100px;
    background: #eee;
  }
}
.sportmassan .module-sponsors {
  background-color: #808080;
}
.sportmassan .module-sponsors h2.text-small {
  text-transform: initial;
  margin-top: 0;
}
.sportmassan .module-sponsors .group {
  position: relative;
  padding: 30px 0 60px 0;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .sportmassan .module-sponsors .group {
    padding: 30px 0;
  }
  @supports (display: grid) {
    .sportmassan .module-sponsors .group {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
  }
}
@media screen and (min-width: 1024px) {
  @supports (display: grid) {
    .sportmassan .module-sponsors .group {
      grid-gap: 70px;
    }
  }
}
.sportmassan .module-sponsors .logotypes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@supports (display: grid) {
  .sportmassan .module-sponsors .logotypes--large {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 70px;
  }
  @media screen and (min-width: 600px) {
    .sportmassan .module-sponsors .logotypes--large {
      grid-gap: 70px 140px;
    }
  }
  @media screen and (min-width: 768px) {
    .sportmassan .module-sponsors .logotypes--large {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 100px;
    }
  }
  @media screen and (min-width: 1350px) {
    .sportmassan .module-sponsors .logotypes--large {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 100px 140px;
    }
  }
}
@supports (display: grid) {
  .sportmassan .module-sponsors .logotypes--medium {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 35px;
  }
  @media screen and (min-width: 600px) {
    .sportmassan .module-sponsors .logotypes--medium {
      grid-gap: 70px;
    }
  }
  @media screen and (min-width: 1024px) {
    .sportmassan .module-sponsors .logotypes--medium {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 70px;
    }
  }
  @media screen and (min-width: 1350px) {
    .sportmassan .module-sponsors .logotypes--medium {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 70px;
    }
  }
}
@supports (display: grid) {
  .sportmassan .module-sponsors .logotypes--small {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 15px;
  }
  @media screen and (min-width: 600px) {
    .sportmassan .module-sponsors .logotypes--small {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 50px;
    }
  }
  @media screen and (min-width: 768px) {
    .sportmassan .module-sponsors .logotypes--small {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 75px;
    }
  }
  @media screen and (min-width: 1024px) {
    .sportmassan .module-sponsors .logotypes--small {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 50px;
    }
  }
  @media screen and (min-width: 1350px) {
    .sportmassan .module-sponsors .logotypes--small {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
  @media screen and (min-width: 1920px) {
    .sportmassan .module-sponsors .logotypes--small {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
}
@media screen and (min-width: 768px) {
  .sportmassan .module-sponsors .logotypes {
    min-height: 200px;
  }
}
.sportmassan .module-sponsors .logotypes > * {
  align-self: center;
}
.sportmassan .module-sponsors .logotypes a {
  float: left;
  width: 15%;
  margin: 0 2.5%;
}
@supports (display: flex) {
  .sportmassan .module-sponsors .logotypes a {
    float: none;
    width: auto;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.sportmassan .module-sponsors .logotypes a figure {
  float: none;
  width: auto;
  margin: 0;
}
.module-sponsors figure {
  float: left;
  width: 15%;
  margin: 0 2.5%;
}
@supports (display: flex) {
  .module-sponsors figure {
    float: none;
    width: auto;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.module-sponsors img {
  max-width: 100%;
  width: 300px;
  height: auto;
}

@media screen and (min-width: 800px) {
  .module-text .text {
    width: 700px;
  }
}

.module-textandmedia .section-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 0;
  justify-content: center;
  overflow: hidden;
  padding-top: 1em;
  padding-bottom: 1em;
}
@supports (display: grid) {
  .module-textandmedia .section-content {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .module-textandmedia .section-content {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    align-items: center;
  }
}
.module-textandmedia.color-1 .section-content, .module-textandmedia.color-2 .section-content, .module-textandmedia.color-3 .section-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.module-textandmedia .text {
  padding-left: 50px;
}
@supports (display: grid) {
  .module-textandmedia .text {
    padding-left: 0;
  }
}
.module-textandmedia .text,
.module-textandmedia .media {
  float: left;
  width: 50%;
}
@supports (display: grid) {
  .module-textandmedia .text,
.module-textandmedia .media {
    width: auto;
  }
}
@media screen and (min-width: 768px) {
  .module-textandmedia .media--right .text,
.module-textandmedia .media--right .media {
    float: right;
    width: 50%;
  }
  @supports (display: grid) {
    .module-textandmedia .media--right .text,
.module-textandmedia .media--right .media {
      width: auto;
    }
  }
  .module-textandmedia .media--right .text {
    grid-column: 1;
    grid-row: 1;
    padding-right: 50px;
  }
  @supports (display: grid) {
    .module-textandmedia .media--right .text {
      padding-right: 0;
    }
  }
  .module-textandmedia .media--right .media {
    grid-column: 2;
  }
}
.module-textandmedia figure {
  display: flex;
  justify-content: center;
  line-height: 0.01;
}
.module-textandmedia figure img {
  width: 100%;
}
.module-textandmedia h2, .module-textandmedia .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .module-textandmedia h2.text-small {
  margin-top: 1rem;
  max-width: 30ch;
}
@media screen and (min-width: 768px) {
  .module-textandmedia h2, .module-textandmedia .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .module-textandmedia h2.text-small {
    margin-top: 0;
    padding-top: 0;
  }
}
.module-textandmedia p {
  max-width: 60ch;
}
.module-textandmedia .media + .text {
  margin-top: 2rem;
}
@media screen and (min-width: 768px) {
  .module-textandmedia .media + .text {
    margin-top: 0;
  }
}

.top-image + .module-textandmedia.color-1,
.top-image + .module-textandmedia.color-2,
.top-image + .module-textandmedia.color-3 {
  margin-top: 0;
}

.module-textandmedia.color-1 + .module-textandmedia.color-1,
.module-textandmedia.color-1 + .module-textandmedia.color-2,
.module-textandmedia.color-1 + .module-textandmedia.color-3,
.module-textandmedia.color-2 + .module-textandmedia.color-1,
.module-textandmedia.color-2 + .module-textandmedia.color-2,
.module-textandmedia.color-2 + .module-textandmedia.color-3,
.module-textandmedia.color-3 + .module-textandmedia.color-1,
.module-textandmedia.color-3 + .module-textandmedia.color-2,
.module-textandmedia.color-3 + .module-textandmedia.color-3 {
  margin-top: -80px;
}

.external .module-textandmedia figure {
  border: 1px solid #eee;
  padding: 3px;
}

@supports (display: grid) {
  .block-video .section-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px 20px;
  }
}
.block-video .item {
  margin-bottom: 20px;
}
@supports (display: grid) {
  .block-video .item {
    grid-column: span 2;
    margin: 0;
  }
}
@media screen and (min-width: 600px) {
  .block-video .item.width-50 {
    width: 46%;
    float: left;
    margin-left: 2%;
    margin-right: 2%;
  }
  @supports (display: grid) {
    .block-video .item.width-50 {
      grid-column: span 1;
      width: auto;
      float: none;
      margin: 0;
    }
  }
}
.block-video .embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.block-video .embed-container iframe,
.block-video .embed-container object,
.block-video .embed-container embed,
.block-video .embed-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.type-project .block-video {
  margin-top: -30px;
}

.module-form.color-1 .section-content, .module-form.color-2 .section-content, .module-form.color-3 .section-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.module-form .freeform-form-has-errors {
  border: 1px solid #808080;
  padding: 20px;
}
.module-form .freeform-form-has-errors ul {
  margin-bottom: 0;
}
.module-form .successful-form-submission {
  margin-top: 50px;
  border: 1px solid #222;
  padding: 20px;
}
.module-form .successful-form-submission p {
  margin-bottom: 0;
}
.module-form .freeform-form-has-errors,
.module-form .freeform-row .freeform-column .freeform-errors > li, .module-form .freeform-row .freeform-column .ff-errors > li {
  color: #808080 !important;
}

.section.module-district-map {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .section.module-district-map {
    background-color: unset;
    margin-top: unset;
    margin-bottom: unset;
    padding-bottom: unset;
  }
}
.section.module-district-map .course-profile-image {
  display: none;
  width: 100%;
  padding: 0 60px;
}
@media screen and (min-width: 768px) {
  .section.module-district-map .course-profile-image {
    display: block;
  }
}
.section.module-district-map .districts-overview {
  position: relative;
  height: 400px;
  margin-bottom: 50px;
}
.section.module-district-map .districts-overview::after {
  content: "";
  display: block;
  background: #808080;
  left: 8px;
  width: 4px;
  height: 400px;
  position: absolute;
}
@media screen and (min-width: 768px) {
  .section.module-district-map .districts-overview {
    height: 100px;
    padding: 0 50px;
    width: 100%;
  }
  .section.module-district-map .districts-overview::after {
    background: #808080;
    top: calc(50% - 2px);
    left: unset;
    width: calc(100% - 100px);
    height: 4px;
  }
}
.section.module-district-map .districts-overview .mobile-district-image {
  display: none;
  position: absolute;
  top: 125px;
  right: 0;
  width: 150px;
  height: 150px;
}
.section.module-district-map .districts-overview .mobile-district-image img {
  border-radius: 50%;
}
.section.module-district-map .districts-overview .mobile-district-image.selected {
  display: block;
}
@media screen and (min-width: 370px) {
  .section.module-district-map .districts-overview .mobile-district-image {
    width: 200px;
    height: 200px;
    top: 100px;
  }
}
@media screen and (min-width: 430px) {
  .section.module-district-map .districts-overview .mobile-district-image {
    width: 250px;
    height: 250px;
    top: 75px;
  }
}
@media screen and (min-width: 600px) {
  .section.module-district-map .districts-overview .mobile-district-image {
    width: 300px;
    height: 300px;
    top: 50px;
  }
}
@media screen and (min-width: 768px) {
  .section.module-district-map .districts-overview .mobile-district-image {
    display: none;
  }
  .section.module-district-map .districts-overview .mobile-district-image.selected {
    display: none;
  }
}
.section.module-district-map button.district-tab {
  border-radius: 50%;
  max-height: 20px;
  max-width: 20px;
  padding: 0;
  margin: 0;
  background: #808080;
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 1;
  border: 2px solid #F6F4F2;
  cursor: pointer;
}
.section.module-district-map button.district-tab.pulse-animation {
  animation-name: pulse;
  animation-duration: 0.6s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.section.module-district-map button.district-tab.selected {
  background-color: #AB1C46;
}
.section.module-district-map button.district-tab.selected span.tab-title {
  color: #AB1C46;
}
.section.module-district-map button.district-tab span.tab-title {
  position: absolute;
  font-family: neuzeit-grotesk, sans-serif;
  font-weight: 800;
  font-size: 12px;
  left: 30px;
  top: -2px;
}
.section.module-district-map button.district-tab.end span.tab-title, .section.module-district-map button.district-tab.start span.tab-title {
  font-family: fira-sans-condensed, sans-serif;
  font-weight: unset;
  font-size: 16px;
}
@keyframes pulse {
  0% {
    background-color: #f47e3a;
  }
}
@media screen and (min-width: 768px) {
  .section.module-district-map button.district-tab {
    top: calc(50% - 10px);
    display: flex;
    justify-content: center;
  }
  .section.module-district-map button.district-tab span.tab-title {
    top: unset;
    left: unset;
    position: relative;
    margin-top: 20px;
  }
  .section.module-district-map button.district-tab:nth-of-type(2n) span.tab-title {
    top: unset;
    bottom: unset;
    margin-top: -26px;
  }
  .section.module-district-map button.district-tab.end {
    right: 50px;
  }
  .section.module-district-map button.district-tab.end span.tab-title {
    position: absolute;
    left: unset;
    right: -50px;
    top: -2px;
    margin-top: 0;
  }
  .section.module-district-map button.district-tab.start {
    left: 50px;
  }
  .section.module-district-map button.district-tab.start span.tab-title {
    position: absolute;
    left: -50px;
    top: -2px;
    margin-top: 0;
  }
}
.section.module-district-map .districts {
  position: relative;
  padding-bottom: 50px;
}
.section.module-district-map .districts #districts-select {
  position: absolute;
  width: 150px;
  bottom: 0;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  left: calc(50% - 75px);
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .section.module-district-map .districts #districts-select {
    right: 40px;
    top: 40px;
    left: unset;
    bottom: unset;
  }
}
.section.module-district-map .district {
  background-color: #fff;
  padding: 0 10px;
}
@media screen and (min-width: 768px) {
  .section.module-district-map .district {
    padding: 70px 40px 50px 40px;
  }
}
.section.module-district-map .district.hidden {
  display: none;
}
.section.module-district-map .district .district-title {
  min-height: 40px;
}
.section.module-district-map .district .district-title.hide-on-mobile {
  display: none;
}
@media screen and (min-width: 768px) {
  .section.module-district-map .district .district-title.hide-on-mobile {
    display: block;
  }
  .section.module-district-map .district .district-title.hide-on-desktop {
    display: none;
  }
  .section.module-district-map .district .district-title.hide-on-desktop.hide-on-mobile {
    display: none;
  }
}
.section.module-district-map .district .text-and-image .district-image {
  display: block;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .section.module-district-map .district .text-and-image .district-image {
    float: right;
    max-width: calc(50% - 50px);
    margin: 0 0 40px 50px;
  }
}
.section.module-district-map .district .text-and-image .district-bottom-image {
  display: none;
}
@media screen and (min-width: 768px) {
  .section.module-district-map .district .text-and-image .district-bottom-image {
    display: block;
  }
}
.section.module-district-map .district .text-and-image .district-bottom-image img {
  width: 100%;
  height: auto;
}

.block-api .section-content .cards-wrapper {
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 768px) {
  .block-api .section-content .cards-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1024px) {
  .block-api .section-content .cards-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.block-api .section-content .cards-wrapper .card {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.01), 0 8px 8px rgba(0, 0, 0, 0.01), 0 16px 16px rgba(0, 0, 0, 0.01);
}
.block-api .section-content .cards-wrapper .card .img {
  border-radius: 0.25rem 0.25rem 0 0;
}
.block-api .section-content .cards-wrapper .card .img img {
  width: 100%;
  height: 100%;
  border-radius: 0.25rem 0.25rem 0 0;
  object-fit: cover;
}
.block-api .section-content .cards-wrapper .card .img svg {
  width: 100%;
  height: 100%;
  border-radius: 0.25rem 0.25rem 0 0;
}
.block-api .section-content .cards-wrapper .card .text {
  background: #fff;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 1rem 20px 1.5rem;
}
@media screen and (min-width: 768px) {
  .block-api .section-content .cards-wrapper .card .text {
    padding: 1rem 40px 1.5rem;
  }
}
.block-api .section-content .cards-wrapper .card .text .category {
  width: fit-content;
  margin-bottom: 12px;
  padding: 6px;
  border-radius: 0.25rem;
  background-color: #f47e3a;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
}
.block-api .section-content .cards-wrapper .card .text .location {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  font-size: 0.9rem;
}
.block-api .section-content .cards-wrapper .card .text .dates {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 0.9rem;
}
.block-api .section-content .cards-wrapper .card .text svg {
  margin-right: 6px;
}
.block-api .section-content .cards-wrapper .card .button {
  margin: 10px;
  text-align: center;
}

@media print {
  body > *:not(main) {
    display: none;
  }
}
body {
  background: #eee;
  color: #ccc;
}

main {
  background: #fff;
}

main > .section-main-introduction {
  background: #5383a2;
  color: #fff;
}

.top-image {
  height: 500px;
}
@media screen and (min-width: 350px) {
  .top-image {
    height: 500px;
  }
}
@media screen and (min-width: 768px) {
  .top-image {
    height: 75vh;
  }
}

h1 {
  color: #333;
  font-weight: 400;
  text-transform: uppercase;
}

h2, .sportmassan .module-sponsors h2.text-small {
  font-size: 1.5em;
  line-height: 1.2;
  font-weight: 400;
  text-transform: uppercase;
}
@media screen and (min-width: 1024px) {
  h2, .sportmassan .module-sponsors h2.text-small {
    font-size: 2em;
    line-height: 1.2;
  }
}

.header .branding .logo {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 40%;
}
@media screen and (min-width: 1920px) {
  .header .branding .logo {
    width: 50%;
  }
}
.header .cta {
  width: 80%;
  margin: 0 10%;
}

.top-image {
  background-color: #294c60;
}

.info {
  font-family: fira-sans-condensed, sans-serif;
}
.info h2, .info .sportmassan .module-sponsors h2.text-small, .sportmassan .module-sponsors .info h2.text-small {
  text-transform: none;
}

.section {
  color: #000;
}

.section h2 span, .section .sportmassan .module-sponsors h2.text-small span, .sportmassan .module-sponsors .section h2.text-small span {
  color: #5383a2;
}