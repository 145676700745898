// ============================================================================
// Module: FAQ
// ============================================================================

.module-faq {
    .text {
        @include respond-to($tablet) {
            text-align: center;
        }
    }

    .faqs {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

        @include respond-to($tablet) {
            margin: 3em 5% 0 5%;
        }
    }

    .faq {
        width: 100%;
        margin: 0 0 2em 0;

        &.large {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 45px;

            @include respond-to(1200px) {
                width: 50%;
                padding-right: 50px;
                margin-bottom: 60px;
            }
        }

        img {
            float: left;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            margin-right: 20px;
            flex: none;

            @include respond-to($large-phone) {
                width: 100px;
                height: 100px;
                margin-right: 30px;
            }

            @include respond-to($desktop) {
                width: 175px;
                height: 175px;
            }

            @include respond-to(1200px) {
                width: 100px;
                height: 100px;
            }

            @include respond-to(1400px) {
                width: 175px;
                height: 175px;
            }
        }

        .qa {
            flex: 0 1 auto;

            .question {
                font-weight: bold;
            }
        }
    }
}
