// ============================================================================
// External Sites: Mixins
// ============================================================================

// Respond to
$media-queries: true !default;
$media-query-free-breakpoint: 900px;

@mixin respond-to($min-width, $max-width: false) {
    @if $media-queries {
        @media screen and (min-width: $min-width) {
            @if $max-width {
                @media (max-width: $max-width) {
                    @content;
                }
            } @else {
                @content;
            }
        }
    } @else if $min-width <= $media-query-free-breakpoint and (not $max-width or $max-width and $max-width >= $media-query-free-breakpoint) {
        @content;
    }
}

// Font smoothing
@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

// Outline
@mixin outline() {
    outline: none;
    box-shadow: 0 0 5px rgba($color-focus, 0.5);
}

// Vertical rhythm
@mixin vertical-rhythm($r, $g, $b, $a) {
    background-image: linear-gradient(rgba($r, $g, $b, $a) 1px, transparent 1px);
    background-size: 100% 1.5em;
}

// Text Overflow (Element needs to be block or inline-block)
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
