// ============================================================================
// Module: Card
// ============================================================================

.module-card {
    .section-content > ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .card + .card {
        margin-top: 1.5rem;
    }

    @supports (display: grid) {
        .section-content > ul {
            margin: 0;
            padding: 0;

            @include respond-to($tablet) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto;
                grid-column-gap: 1.5rem;
                grid-row-gap: 1.5rem;
            }

            @include respond-to(1100px) {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        .card + .card {
            margin-top: 0;
        }
    }

    // Card
    .card {
        background: #fff;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: column;
        position: relative;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01),
        0 2px 2px rgba(0, 0, 0, 0.01),
        0 4px 4px rgba(0, 0, 0, 0.01),
        0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.01);
        margin-bottom: 1.5rem;

        @include respond-to($tablet) {
            margin-bottom: 0;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            opacity: 0;
            border-radius: 0.25rem;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03),
            0 2px 2px rgba(0, 0, 0, 0.03),
            0 4px 4px rgba(0, 0, 0, 0.03),
            0 8px 8px rgba(0, 0, 0, 0.03),
            0 16px 16px rgba(0, 0, 0, 0.03);
            transition: opacity 0.5s ease-in-out;
        }

        &:hover::after {
            opacity: 1;
        }

        &:focus {
            border: 1px solid red;
        }

        // Image
        .img {
            border-radius: 0.25rem 0.25rem 0 0;
            // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 1rem));

            &.color-1,
            &.color-2,
            &.color-3 {
                h3 * {
                    color: inherit;
                }
            }

            img {
                border-radius: 0.25rem 0.25rem 0 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        figure,
        picture {
            line-height: 0.01;

            &.dynamic-height {
                height: 100%;
            }
        }

        // Text
        .text {
            padding: 1rem 20px 1.5rem 20px;
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            @include respond-to($tablet) {
                padding: 1rem 40px 1.5rem 40px;
            }

            > p + p {
                margin-top: -0.75rem;
            }

            p:last-child {
                margin-bottom: 0;
            }

            .button {
                margin-top: 0.5rem;
                margin-bottom: 0.5em;
            }
        }

        h3 {
            font-size: 20px;

            @include respond-to($tablet) {
                font-size: 28px;
            }

            a {
                color: #000;

                &:hover {
                    text-decoration: underline;
                }

                &:focus {
                    text-decoration: underline;
                }
            }

            span {
                color: #6e6259;
            }
        }

        p {
            max-width: 60ch;
            // display: none;

            @include respond-to($tablet) {
                display: unset;
            }
        }

        a {
            text-decoration: underline;
        }

        a.button {
            text-decoration: none;
        }

        // Widths
        @include respond-to($tablet) {
            &--width-33 {
                // No Grid
                float: left;
                width: 45%;
                margin: 0 2.5%;

                // Grid
                @supports (display: grid) {
                    float: none;
                    width: auto;
                    min-height: unset;
                    margin: 0;
                    grid-column: span 1;
                }
            }

            &--width-66 {
                // No Grid
                float: left;
                width: 95%;
                margin: 0 2.5%;

                // Grid
                @supports (display: grid) {
                    float: none;
                    width: auto;
                    min-height: unset;
                    margin: 0;
                    grid-column: span 2;
                }
            }

            &--width-100 {
                grid-column: span 2;
                padding-bottom: 0;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr;

                @include respond-to($tablet) {
                    flex-direction: row;
                }

                .img {
                    height: auto !important;
                    width: 50%;

                    @supports (display: grid) {
                        float: none;
                        width: auto;
                    }
                }

                .img img {
                    border-radius: 0.25rem 0 0 0.25rem;
                    max-width: 700px;
                }

                .text {
                    justify-content: center;
                    align-items: flex-start;
                    width: 50%;
                    box-sizing: border-box;

                    @supports (display: grid) {
                        float: none;
                        width: auto;
                    }

                    h3 {
                        max-width: 100%;
                    }
                }
            }
        }

        @include respond-to(1100px) {
            &--width-33 {
                // No Grid
                float: left;
                width: 28%;
                margin: 0 2.5%;

                // Grid
                @supports (display: grid) {
                    float: none;
                    width: auto;
                    margin: 0;
                    grid-column: span 1;
                }
            }

            &--width-100 {
                grid-column: span 3;
            }
        }
    }
}
