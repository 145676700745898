.block-api {
    .section-content {
        .cards-wrapper {
            display: grid;
            grid-gap: 16px 24px;
            grid-template-columns: 1fr;

            @include respond-to($tablet) {
                grid-template-columns: 1fr 1fr;
            }

            @include respond-to($desktop) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            .card {
                display: flex;
                position: relative;
                flex-direction: column;
                border-radius: 0.25rem;
                background: #fff;
                box-shadow: 0 1px 1px rgba(0 0 0 / 1%), 0 2px 2px rgba(0 0 0 / 1%), 0 4px 4px rgba(0 0 0 / 1%),
                    0 8px 8px rgba(0 0 0 / 1%), 0 16px 16px rgba(0 0 0 / 1%);

                .img {
                    border-radius: 0.25rem 0.25rem 0 0;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 0.25rem 0.25rem 0 0;
                        object-fit: cover;
                    }

                    svg {
                        width: 100%;
                        height: 100%;
                        border-radius: 0.25rem 0.25rem 0 0;
                    }
                }

                .text {
                    background: #fff;
                    display: flex;
                    flex: 1 0 auto;
                    flex-direction: column;
                    padding: 1rem 20px 1.5rem;

                    @include respond-to($tablet) {
                        padding: 1rem 40px 1.5rem;
                    }

                    .category {
                        width: fit-content;
                        margin-bottom: 12px;
                        padding: 6px;
                        border-radius: 0.25rem;
                        background-color: $color-1;
                        color: #fff;
                        font-size: 10px;
                        font-weight: 700;
                        line-height: 1em;
                        text-transform: uppercase;
                    }

                    .location {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 12px;
                        font-size: .9rem;
                    }

                    .dates {
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;
                        font-size: .9rem;
                    }

                    svg {
                        margin-right: 6px;
                    }
                }

                .button {
                    margin: 10px;
                    text-align: center;
                }
            }
        }
    }
}
