// ============================================================================
// Module: Map
// ============================================================================

.module-map {
    .text {
        @include respond-to($tablet) {
            text-align: center;
        }
    }

    iframe {
        height: 600px;
        width: 100%;
    }
}
