// ============================================================================
// Module: Image
// ============================================================================

.module-image {
    img {
        width: 100%;
        height: auto;
    }
}
