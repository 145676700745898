// ============================================================================
// Module: Text
// ============================================================================

.module-text {
    .text {
        @include respond-to(800px) {
            width: 700px;
        }
    }
}
