// ============================================================================
// Module: Events
// ============================================================================

.module-events {

    // Full width layout on small screens, "card" layout on large ones.
    .section-content {
        padding-top: 20px;
        padding-bottom: 20px;

        @include respond-to($tablet) {
            background: #fff;
            background: unset;
            padding-top: 0;
            padding-bottom: 0;
            //margin-bottom: 80px;
        }

        .box {
            @include respond-to($tablet) {
                background: #fff;
                padding: 40px;
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01),
                0 2px 2px rgba(0, 0, 0, 0.01),
                0 4px 4px rgba(0, 0, 0, 0.01),
                0 8px 8px rgba(0, 0, 0, 0.01),
                0 16px 16px rgba(0, 0, 0, 0.01);
            }
        }

        h2 {
            max-width: 15ch;
        }

        a {
            color: #000;
            text-decoration: none;
        }

        .text {
            max-width: 60ch;
            margin: 2rem 0 3rem 0;
        }

        .events {
            overscroll-behavior-x: contain;
            scroll-snap-type: x proximity;
            scrollbar-width: none;

            @supports (display: grid) {
                overflow-x: scroll;
                display: grid;
                grid-template-columns: 200px;
                grid-auto-columns: 200px;
                grid-auto-flow: column;
                grid-gap: 20px;
                padding-bottom: 20px;
            }

            @include respond-to($tablet) {
                overflow: hidden;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-auto-flow: unset;
                grid-template-rows: auto;
                grid-gap: 40px;
                padding-bottom: 0;
            }

            @include respond-to($desktop) {
                grid-gap: 40px cp($grid-desktop-gutter, $grid-desktop-content);
                grid-template-columns: repeat(4, 1fr);
            }
        }

        /*
        .events::-webkit-scrollbar {
            display: none;
            inline-size: 0 !important;
        }
        */

        .items::-webkit-scrollbar {
            display: unset;
            width: 5px;
            height: 8px;
            background-color: transparent;
        }

        .items::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.2);
            border-radius: 6px;
        }

        .event {
            background: #fff;
            position: relative;
            scroll-snap-align: start;

            // No Grid
            float: left;
            width: 45%;
            min-height: 370px;
            margin: 0 2.5%;

            // Grid
            @supports (display: grid) {
                float: none;
                width: auto;
                min-height: unset;
                margin: 0;
            }

            @include respond-to($tablet) {
                background: none;

                // No Grid
                float: left;
                width: 28.3333%;
                min-height: 420px;
                margin: 0 2.5%;

                // Grid
                @supports (display: grid) {
                    float: none;
                    width: auto;
                    min-height: unset;
                    margin: 0;
                }
            }

            @include respond-to($desktop) {
                // No Grid
                float: left;
                width: 20%;
                min-height: 420px;
                margin: 0 2.5%;

                // Grid
                @supports (display: grid) {
                    float: none;
                    width: auto;
                    min-height: unset;
                    margin: 0;
                }

                &:hover h3 a {
                    text-decoration: underline;
                }
            }

            .event-text {
                padding: 5px 15px;

                @include respond-to($tablet) {
                    padding: 0;
                }
            }

            .date {
                position: absolute;
                top: 0;
                left: 0;
                min-width: 36px;
                background: #e4002b;
                color: #fff;
                text-align: center;
                font-size: 10px;
                line-height: 1.7;
                font-weight: 500;
                z-index: 1;

                @include respond-to($tablet) {
                    font-size: 12px;
                    min-width: 48px;
                }

                &--day {
                    width: 100%;
                    background: #fff;
                    color: #000;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 1.3;

                    @include respond-to($tablet) {
                        font-size: 20px;
                    }
                }

                &--month {
                    text-transform: uppercase;
                }

                &--text {
                    padding: 0 10px;
                }
            }

            figure,
            picture {
                display: block;
                line-height: 0.01;

                img {
                    width: 100%;
                }
            }

            .svg {
                background: #eee;
                width: 100%;
                padding-bottom: 75%;

                picture {
                    position: relative;
                }

                img {
                    position: absolute;
                    width: 70%;
                    padding-top: 10%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}
