// ============================================================================
// External Sites: Settings
// ============================================================================

@use "sass:math";

// Responsive breakpoints
$phone: 350px;
$large-phone: 600px;
$tablet: 768px;
$desktop: 1024px;
$widescreen: 1920px;

$layout-max-width: 1920px;
$content-max-width: 1440px;

@function calc-percent($target, $container) {
    @return math.div($target, $container) * 100%;
}

@function cp($target, $container) {
    @return calc-percent($target, $container);
}

// Grid

/*
MOBILE (12 COL GRID):       5 + 12 x (24 + 2)  +   5  =>   320 px
TABLET (12 COL GRID):      22 + 12 x (45 + 40) +  22  =>  1115 px
DESKTOP (12 COL GRID):    240 + 12 x (60 + 40) + 240  =>  1920 px
WIDESCREEN (12 COL GRID): 240 + 12 x (60 + 40) + 240  =>  1920 px
*/

$grid-mobile-margin: 5;
$grid-mobile-column: 24;
$grid-mobile-gutter: 2;
$grid-mobile-width: 320;
$grid-mobile-content: $grid-mobile-width - 2 * $grid-mobile-margin;

$grid-tablet-margin: 22;
$grid-tablet-column: 45;
$grid-tablet-gutter: 40;
$grid-tablet-width: 1024;
$grid-tablet-content: $grid-tablet-width - 2 * $grid-tablet-margin;

$grid-desktop-margin: 240;
$grid-desktop-column: 76;
$grid-desktop-gutter: 48;
$grid-desktop-width: 1920;
$grid-desktop-content: $grid-desktop-width - 2 * $grid-desktop-margin;

$grid-widescreen-margin: 240;
$grid-widescreen-column: 76;
$grid-widescreen-gutter: 48;
$grid-widescreen-width: 1920;
$grid-widescreen-content: $grid-widescreen-width - 2 * $grid-widescreen-margin;

// Fonts
$font-base: serifa, serif;
$font-complement: serifa, serif;

.font--serifa {
    font-family: $font-base;
}

.vertical-rhythm {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px);
    background-size: 100% 1.45em;
}

.no-ligatures {
    font-feature-settings: "liga" 0;
    @supports (font-variant-ligatures: no-common-ligatures) {
        font-feature-settings: normal;
        font-variant-ligatures: no-common-ligatures;
    }
}

.discretionary-ligatures {
    font-feature-settings: "dlig" 1, "hist" 1;
    @supports (font-variant-ligatures: discretionary-ligatures historical-ligatures) {
        font-feature-settings: normal;
        font-variant-ligatures: discretionary-ligatures historical-ligatures;
    }
}

.diagonal-fractions {
    @supports (font-variant-numeric: diagonal-fractions) {
        font-feature-settings: normal;
        font-variant-numeric: diagonal-fractions;
    }
}

.small-caps {
    font-feature-settings: "smcp" 1, "c2sc" 1;
    @supports (font-variant-caps: all-small-caps) {
        font-feature-settings: normal;
        font-variant-caps: all-small-caps;
    }
}

.proportional-nums {
    font-feature-settings: "pnum" 1;
    @supports (font-variant-numeric: proportional-nums) {
        font-feature-settings: normal;
        font-variant-numeric: proportional-nums;
    }
}

.tabular-nums {
    font-feature-settings: "tnum" 1, "zero" 1;
    @supports (font-variant-numeric: tabular-nums) {
        font-feature-settings: normal;
        font-variant-numeric: tabular-nums slashed-zero;
    }
}

.oldstyle-nums {
    font-feature-settings: "onum" 1;
    @supports (font-variant-numeric: oldstyle-nums) {
        font-feature-settings: normal;
        font-variant-numeric: oldstyle-nums;
    }
}

.lining-nums {
    font-feature-settings: "lnum" 1;
    @supports (font-variant-numeric: lining-nums) {
        font-feature-settings: normal;
        font-variant-numeric: lining-nums;
    }
}

.proportional-lining-nums {
    font-feature-settings: "pnum" 1, "lnum" 1;
    @supports (font-variant-numeric: proportional-nums) {
        font-feature-settings: normal;
        font-variant-numeric: proportional-nums lining-nums;
    }
}

.proportional-oldstyle-nums {
    font-feature-settings: "pnum" 1, "onum" 1;
    @supports (font-variant-numeric: proportional-nums) {
        font-feature-settings: normal;
        font-variant-numeric: proportional-nums oldstyle-nums;
    }
}

.tabular-lining-nums {
    font-feature-settings: "tnum" 1, "lnum" 1;
    @supports (font-variant-numeric: tabular-nums) {
        font-feature-settings: normal;
        font-variant-numeric: tabular-nums lining-nums;
    }
}

.tabular-oldstyle-nums {
    font-feature-settings: "tnum" 1, "onum" 1;
    @supports (font-variant-numeric: tabular-nums) {
        font-feature-settings: normal;
        font-variant-numeric: tabular-nums oldstyle-nums;
    }
}

$color-0: #fff;

$color-1: #1d4f91;
$color-2: #e4002b;
$color-3: #6e6259;
$color-4: #ff8200;
$color-5: #59cbe8;
$color-6: #009f4d;
$color-7: #f99fc9;

$color-0-text: #222;

$color-1-text: #fff;
$color-2-text: #fff;
$color-3-text: #fff;
$color-4-text: #222;
$color-5-text: #222;
$color-6-text: #222;
$color-7-text: #222;

.color-sample {
    float: left;
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 20px 20px 0;

    &--small {
        width: 130px;
        height: 130px;
    }
}

.color-sample div {
    padding: 10px;
    color: inherit;
}

.color-1 {
    background: $color-1;
    color: $color-1-text;
}

.color-2 {
    background: $color-2;
    color: $color-2-text;
}

.color-3 {
    background: $color-3;
    color: $color-3-text;
}

.color-4 {
    background: $color-4;
    color: $color-4-text;
}

.color-5 {
    background: $color-5;
    color: $color-5-text;
}

.color-6 {
    background: $color-6;
    color: $color-6-text;
}

.color-7 {
    background: $color-7;
    color: $color-7-text;
}

$color-primary: $color-1;
$color-secondary: $color-6;

$color-heading: $color-1;
$color-heading-dark: #000;
$color-heading-hover: $color-4;

$color-text: #333;
$color-text-light: $color-3;
$color-link: #000;
$color-hover: #333;
$color-active: #111;

$color-nav: #000;
$color-nav-bg: #fff;
$color-nav-active: #fff;
$color-nav-active-bg: $color-1;

$color-ok: #4d9a3d;
$color-error: #ba2c2c;
$color-focus: $color-4;
$color-placeholder: #ddd;

$color-mobile-nav: rgba(#fff, 0.85);
$color-mobile-nav-2: #eee;
$color-mobile-nav-3: #ddd;
$color-mobile-nav-text: #333;
$color-mobile-nav-focus: $color-6;

$color-footer-bg: #222;
$color-footer-text: #ccc;

$color-notification: #222;
