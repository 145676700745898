// ============================================================================
// External Sites: Typography
// ============================================================================

h1 {
    margin: 1.75em 0 0.5em 0;
    font-size: 1.6em;
    line-height: 1.2;
    font-weight: 900;

    @include respond-to($large-phone) {
        margin: 0 0 0.75em 0;
        font-size: 2em;
    }

    @include respond-to($desktop) {
        margin: 0 0 0.75em 0;
        font-size: 2.5em;
    }

    @include respond-to($widescreen) {
        margin: 0 0 0.75em 0;
        font-size: 3em;
    }
}

h2 {
    margin: 1.5em 0 0.3em 0; // 2 * line height in total
    padding: 0.3em 0;
    font-size: 1.2em;
    line-height: 1.4; // line height / font size

    @include respond-to($large-phone) {
        margin: 1.8em 0 0.6em 0;
        font-size: 2.45em;
        line-height: 1.2;
    }
}

h3 {
    margin: 1em 0 0.5em 0;
    font-size: 1em;
}

h4,
h5,
h6 {
    margin: 1em 0 0.5em 0;
    font-size: 0.9em;
    color: #666;
}

%small {
    font-size: 10px;
    text-transform: uppercase;
}

%medium {
    font-size: 14px;
    text-transform: uppercase;

    @include respond-to($tablet) {
        font-size: 16px;
    }

    @include respond-to($desktop) {
        font-size: 20px;
    }
}

%navigation {
    font-family: $font-complement;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;

    @include respond-to($tablet) {
        font-size: 12px;
    }

    @include respond-to($desktop) {
        font-size: 14px;
    }

    @include respond-to($widescreen) {
        font-size: 16px;
    }
}

%subheading {
    font-family: $font-complement;
    font-size: 12px;
    text-transform: uppercase;
    color: $color-1;
    margin-bottom: 1em;

    @include respond-to($tablet) {
        font-size: 18px;
    }

    @include respond-to($desktop) {
        font-size: 21px;
    }
}

%introduction {
    font-size: 1.2em;
}


small,
.text-small {
    @extend %small;
}

.text-medium {
    @extend %medium;
}

.text-navigation {
    @extend %navigation;
}

.text-subheading {
    @extend %subheading;
}

.section {
    h1,
    h2,
    p {
        color: inherit;
    }

    h1 {
        margin-top: 1em;
    }

    h2 {
        @include respond-to($desktop) {
            margin-top: 0;
        }

        span {
            @extend %subheading;

            display: block;
        }
    }

    .introduction {
        @extend %introduction;
    }
}
