// ============================================================================
// Module: Heading
// ============================================================================

.module-heading {
    h2 {
        @include respond-to($desktop) {
            max-width: 30ch;
        }
    }

    p {
        @include respond-to($desktop) {
            max-width: 60ch;
        }
    }
}
