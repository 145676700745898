// ============================================================================
// Module: Scripts
// ============================================================================

.module-scripts {
    .section-content {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}
