// ============================================================================
// Module: Scroll Info
// ============================================================================

.module-scroll-info {
    --content-max-width: 1400px;
    --space: 10px;

    @include respond-to(500px) {
        --space: 20px;
    }

    // Wrapper
    .scroll-info-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 20px;
        overflow-x: scroll;
        padding-bottom: 20px;
        overscroll-behavior-x: contain;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;

        &::before,
        &::after {
            content: "";
            align-self: stretch;
            // background: yellowgreen;
        }

        &::before {
            padding-inline-end: 0;

            @include respond-to(500px) {
                padding-inline-start: max(
                    var(--space),
                    (100vw - var(--content-max-width)) / 2 - var(--space)
                );
            }
        }

        &::after {
            padding-inline-end: 5px;

            @include respond-to(500px) {
                padding-inline-end: max(
                    var(--space),
                    (100vw - var(--content-max-width)) / 2 - var(--space)
                );
            }
        }
    }

    @supports (-webkit-overflow-scrolling: touch) {
        .scroll-info-wrapper {
            -webkit-overflow-scrolling: touch;
        }
    }

    // Scrollbars
    .scroll-info-wrapper::-webkit-scrollbar {
        display: unset;
        width: 5px;
        height: 8px;
        background-color: transparent;
    }

    .scroll-info-wrapper::-webkit-scrollbar {
        display: none;
        inline-size: 0 !important;
    }

    .scroll-info-wrapper::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 6px;
    }

    // Card
    .scroll-info {
        display: block;
        flex-shrink: 0;
        width: 355px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
        scroll-snap-align: center;

        @include respond-to(500px) {
            width: 375px;
        }

        .text {
            padding: 20px 40px 40px 40px;

            small {
                display: block;
                margin-bottom: 16px;
                color: #6E6259;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 125% */
            }

            h2 {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px; /* 116.667% */
            }

            .info-excerpt p {
                font-weight: 900;
            }

            .info-text p {
            }
        }
    }

    // Buttons

    .level {
        display: flex;
        justify-content: center;

        button {
            background: none;
            border: none;
            box-shadow: none;
            color: #000;
            width: 50px;
            margin: 0;
            padding: 0;
        }

        button[disabled] {
            opacity: 0.2;
        }
    }
}
