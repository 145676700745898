// ============================================================================
// Module: Text and Image
// ============================================================================

.module-form {

    // Blocks with background color
    &.color-1,
    &.color-2,
    &.color-3 {
        .section-content {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }

    // Freeform
    .freeform-form-has-errors {
        border: 1px solid $color-6;
        padding: 20px;

        ul {
            margin-bottom: 0;
        }
    }

    .successful-form-submission {
        margin-top: 50px;
        border: 1px solid #222;
        padding: 20px;

        p {
            margin-bottom: 0;
        }
    }

    .freeform-form-has-errors,
    .freeform-row .freeform-column .freeform-errors > li, .freeform-row .freeform-column .ff-errors > li {
        color: $color-6 !important;
    }
}
