// ============================================================================
// Module: Sponsored content
// ============================================================================

.module-sponsored-content {
    .section-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background: $color-1;
        background-size: cover;
        color: #fff;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        min-height: 500px;

        @include respond-to($tablet) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            padding-top: 50px;
            padding-bottom: 50px;
            min-height: 580px;
        }
    }

    h2 {
        text-transform: uppercase;

        @include respond-to($tablet) {
            max-width: 20ch;
        }
    }

    a {
        color: #fff;
    }

    figure {
        max-width: 50px;
        margin-top: 10px;

        @include respond-to($tablet) {
            max-width: 200px;
            margin: 0;
            align-self: flex-end;
        }
    }
}
