// ============================================================================
// Module: Ad
// ============================================================================

.module-ad {
    .section-content {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 20px cp($grid-mobile-gutter, $grid-mobile-content);

        @include respond-to($tablet) {
            grid-gap: 30px cp($grid-tablet-gutter, $grid-tablet-content);
        }

        @include respond-to($desktop) {
            grid-gap: 30px cp($grid-desktop-gutter, $grid-desktop-content);
        }

        @include respond-to($widescreen) {
            grid-gap: 30px cp($grid-widescreen-gutter, $grid-widescreen-content);
        }

        @include respond-to($tablet) {
            grid-template-columns: 1fr 1fr;
        }

        &:after {
            content: "&nbsp;";
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;

            @supports (display: grid) {
                clear: initial;
                display: initial;
                visibility: initial;
                height: initial;
                content: "";
            }
        }

        figure {
            text-align: center;

            img {
                width: 100%;
            }
        }

        .width-50 {
            // No grid
            float: left;
            width: 45%;
            margin: 0 2.5%;

            // Grid
            @supports (display: grid) {
                float: none;
                width: auto;
                margin: 0;
                grid-column: span 1;
            }
        }

        .width-100 {
            grid-column: span 1;

            @include respond-to($tablet) {
                grid-column: span 2;
            }
        }

        // "Annons" text
        figure:first-child::before {
            content: 'Annons';
            color: #808080;
            font-size: 0.85em;
            position: absolute;
            top: -30px;
            right: cp($grid-mobile-margin, $grid-mobile-width);

            @include respond-to($tablet) {
                right: cp($grid-tablet-margin + $grid-tablet-column * 0.5, $grid-tablet-width);

                @supports (display: grid) {
                    right: cp($grid-mobile-margin, $grid-mobile-width);
                }
            }

            @include respond-to($desktop) {
                right: cp($grid-desktop-margin + $grid-desktop-column * 0.5, $grid-desktop-width);

                @supports (display: grid) {
                    right: cp($grid-mobile-margin, $grid-mobile-width);
                }
            }

            @include respond-to($widescreen) {
                right: cp($grid-widescreen-margin + $grid-widescreen-column * 0.5, $grid-widescreen-width);

                @supports (display: grid) {
                    right: cp($grid-mobile-margin, $grid-mobile-width);
                }
            }
        }
    }
}
