// ============================================================================
// Module: Sponsors
// ============================================================================

.module-sponsors {
    position: relative;
    padding: 0;

    // Main site
    .goteborgsvarvet & {
        .groups {
            padding-bottom: 100px;
        }

        .group {
            position: relative;
            padding: 30px 0 60px 0;
            margin: 0 30px;

            @include respond-to($tablet) {
                padding: 30px 0;

                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 35px;
                }
            }

            @include respond-to($desktop) {
                @supports (display: grid) {
                    grid-gap: 70px;
                }
            }
        }

        h2 {
            text-align: center;
            margin-bottom: 35px;

            @include respond-to($tablet) {
                position: absolute;
                left: -120px;
                top: 110px;
                width: 200px;
                padding-bottom: 15px;
                margin-bottom: 50px;
                border-bottom: 1px solid #ddd;
                transform: rotate(-90deg);
            }

            @include respond-to($desktop) {
                left: -140px;
                top: 120px;
            }

            @include respond-to($widescreen) {
                left: -150px;
                top: 110px;
            }
        }

        .logotypes {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            &--large {
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto;
                    grid-gap: 70px;

                    @include respond-to($large-phone) {
                        grid-gap: 70px 140px;
                    }

                    @include respond-to($tablet) {
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-gap: 100px;
                    }

                    @include respond-to(1350px) {
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-gap: 100px 140px;
                    }
                }
            }

            &--medium {
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: auto;
                    grid-gap: 35px;

                    @include respond-to($large-phone) {
                        grid-gap: 70px;
                    }

                    @include respond-to($desktop) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        grid-gap: 70px;
                    }

                    @include respond-to(1350px) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                        grid-gap: 70px;
                    }
                }
            }

            &--small {
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: auto;
                    grid-gap: 35px;

                    @include respond-to($large-phone) {
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-gap: 50px 100px;
                    }

                    @include respond-to($desktop) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        grid-gap: 50px 100px;
                    }

                    @include respond-to(1350px) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                    }

                    @include respond-to($widescreen) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    }
                }
            }

            @include respond-to($tablet) {
                margin-left: 50px;
                min-height: 200px;
            }

            > * {
                align-self: center;
            }

            a {
                // No Flex
                float: left;
                width: 15%;
                margin: 0 2.5%;

                // Flex
                @supports (display: flex) {
                    float: none;
                    width: auto;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                figure {
                    float: none;
                    width: auto;
                    margin: 0;
                }
            }
        }
    }

    // External sites
    .external & {
        h2 {
            align-self: center;
            display: inline-block;
            text-align: center;
            color: $color-4;
            border-bottom: 1px solid $color-4;
            margin-bottom: 1em;
        }

        .groups {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 2em 0 5em 0;
        }

        .group {
            display: flex;
            flex-direction: column;
            padding: 30px 0;

            @include respond-to($desktop) {
                padding: 30px;
            }
        }

        .logotypes {
            display: flex;
            align-items: center;
            height: 100px;

            @include respond-to($tablet) {
                height: 200px;
            }
        }

        figure {
            max-width: 100px;
            margin: 10px;
            width: 100%;

            @include respond-to($tablet) {
                max-width: 150px;
                margin: 0 20px;
            }
        }

        .divider {
            display: none;

            @include respond-to($tablet) {
                display: unset;
                margin-top: 100px;
                width: 1px;
                height: 100px;
                background: #eee;
            }
        }
    }

    // Main site
    .sportmassan & {

        background-color: $color-4;

        h2.text-small {
            text-transform: initial;
            @extend h2;
            margin-top: 0;
        }

        .group {
            position: relative;
            padding: 30px 0 60px 0;
            margin: 0;

            @include respond-to($tablet) {
                padding: 30px 0;

                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 15px;
                }
            }

            @include respond-to($desktop) {
                @supports (display: grid) {
                    grid-gap: 70px;
                }
            }
        }

        .logotypes {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            &--large {
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto;
                    grid-gap: 70px;

                    @include respond-to($large-phone) {
                        grid-gap: 70px 140px;
                    }

                    @include respond-to($tablet) {
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-gap: 100px;
                    }

                    @include respond-to(1350px) {
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-gap: 100px 140px;
                    }
                }
            }

            &--medium {
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: auto;
                    grid-gap: 35px;

                    @include respond-to($large-phone) {
                        grid-gap: 70px;
                    }

                    @include respond-to($desktop) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        grid-gap: 70px;
                    }

                    @include respond-to(1350px) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                        grid-gap: 70px;
                    }
                }
            }

            &--small {
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: auto;
                    grid-gap: 15px;

                    @include respond-to($large-phone) {
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-gap: 50px;
                    }

                    @include respond-to($tablet) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        grid-gap: 75px;
                    }

                    @include respond-to($desktop) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        grid-gap: 50px;
                    }

                    @include respond-to(1350px) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                    }

                    @include respond-to($widescreen) {
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    }
                }
            }

            @include respond-to($tablet) {
                min-height: 200px;
            }

            > * {
                align-self: center;
            }

            a {
                // No Flex
                float: left;
                width: 15%;
                margin: 0 2.5%;

                // Flex
                @supports (display: flex) {
                    float: none;
                    width: auto;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                figure {
                    float: none;
                    width: auto;
                    margin: 0;
                }
            }
        }
    }

    figure {
        // No Flex
        float: left;
        width: 15%;
        margin: 0 2.5%;

        // Flex
        @supports (display: flex) {
            float: none;
            width: auto;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    img {
        max-width: 100%;
        width: 300px;
        height: auto;
    }
}
