// ============================================================================
// Module: News
// ============================================================================

.module-news {
    .section-content {
        margin: 0 auto;
        padding: 0 8px;

        @include respond-to($tablet) {
            padding: 0 cp($grid-tablet-margin, $grid-tablet-width);
        }

        @include respond-to(1250px) {
            padding: 0 cp($grid-desktop-margin, $grid-desktop-width);
        }

        @include respond-to($widescreen) {
            max-width: $layout-max-width;
            padding: 0 240px;
        }

        h2 {
            margin-left: cp($grid-mobile-margin, $grid-mobile-width);

            @include respond-to($tablet) {
                margin-left: 0;
                margin-bottom: 1rem;
            }
        }

        .news {
            @include respond-to(1250px) {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                grid-template-rows: auto;
                grid-gap: 1em cp($grid-desktop-gutter, $grid-desktop-content);

                li:nth-child(2) {
                    margin-top: 75px;
                }

                li:nth-child(5) {
                    margin-bottom: 50px;
                }
            }
        }
    }

    .text-subheading {
        color: #6e6259;
    }
}

.news {
    list-style: none;
    margin: 0;
    padding: 0;

    @include respond-to($tablet) {
        background: #fff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01),
        0 2px 2px rgba(0, 0, 0, 0.01),
        0 4px 4px rgba(0, 0, 0, 0.01),
        0 8px 8px rgba(0, 0, 0, 0.01),
        0 16px 16px rgba(0, 0, 0, 0.01);
    }
}

// Basic news item used on archive page
.newsitem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0.5em 0;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01),
    0 2px 2px rgba(0, 0, 0, 0.01),
    0 4px 4px rgba(0, 0, 0, 0.01),
    0 8px 8px rgba(0, 0, 0, 0.01),
    0 16px 16px rgba(0, 0, 0, 0.01);

    @include respond-to($tablet) {
        background: none;
        box-shadow: none;
    }

    @include respond-to(1250px) {
        flex-direction: row;
        margin: 0;
    }

    &:hover {
        h3 a {
            text-decoration: underline;
        }
    }

    .text {
        order: 2;

        @include respond-to($tablet) {
            order: unset;
        }
    }

    .image {
        order: 1;

        @include respond-to($tablet) {
            order: unset;
        }
    }

    img {
        flex: 0 0 auto;
        max-width: 100%;
    }

    a {
        color: #000;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    // Hifhlighted news item in News block
    &--highlight {
        .text {
            margin: 0 10px 10px 10px;
        }

        .image {
            margin: 10px 10px 0 10px;

            @include respond-to($tablet) {
                margin: 10px;
            }
        }

        @include respond-to($tablet) {
            grid-column: span 12;
            flex-direction: row;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;

            .text {
                width: cp(5 * $grid-tablet-column + 4 * $grid-tablet-gutter, $grid-tablet-content);
                margin: 0 cp($grid-tablet-column, $grid-tablet-content);
                padding: 100px 0;
            }

            .image {
                width: cp(5 * $grid-tablet-column + 5 * $grid-tablet-gutter, $grid-tablet-content);
                line-height: 0.01;
                align-self: stretch;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                margin: 0;
                max-width: 100%;
                width: 100%;
            }
        }

        @include respond-to(1250px) {
            grid-column: span 8;
            grid-row: span 4;
            border: 0;

            .text {
                width: cp(3 * $grid-desktop-column + 4 * $grid-desktop-gutter, 8 * $grid-desktop-column + 7 * $grid-desktop-gutter);
                margin: 0 cp(0.5 * $grid-desktop-column, 8 * $grid-desktop-column + 7 * $grid-desktop-gutter);
                padding: 100px 0;
            }

            .image {
                width: cp(4 * $grid-desktop-column + 3 * $grid-desktop-gutter, 8 * $grid-desktop-column + 7 * $grid-desktop-gutter);
                margin: 10px;
                line-height: 0.01;
                align-self: stretch;
            }
        }
    }

    // Non highlighted items in News block
    &--list {
        flex-direction: row-reverse;
        justify-content: flex-end;
        border: none;

        @include respond-to($tablet) {
            grid-column: 2 / 12;
            margin: 0 cp($grid-tablet-column, $grid-tablet-content);
            padding-top: 1em;
        }

        @include respond-to(1250px) {
            grid-column: span 4;
            margin: 0;
            padding: 0;
        }

        h3 {
            padding-top: 0;
            margin-right: 20px;

            span {
                color: $color-3;
                margin: 0 0 0.5em 0;
            }
        }

        .image {
            line-height: 0.01;
            margin: 10px 20px 10px 10px;

            @include respond-to(1250px) {
                margin: 0 20px 0 0;
            }
        }

        img {
            flex: 0 0 auto;
            max-width: 120px;
        }
    }

    // Read more
    &--readmore {
        background: none;
        box-shadow: none;

        @include respond-to($tablet) {
            padding-bottom: 1em;
        }

        a {
            display: block;
            width: 100%;
            text-align: center;
            margin: 10px 0;
            text-decoration: underline;

            @include respond-to($tablet) {
                text-align: left;
            }
        }
    }
}
