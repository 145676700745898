// ============================================================================
// Module: Course
// ============================================================================

.module-course {
    .text {
        @include respond-to($tablet) {
            text-align: center;
        }
    }
}
