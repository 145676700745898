// ============================================================================
// Block: District map
// ============================================================================

.section.module-short-info {
    background-color: #fff;
    margin-top: 0;
    padding-top: 30px;


    .mobile-top-content {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: 666;
            height: 100%;
            width: 100px;
            background: -webkit-gradient(linear,left top,right top,color-stop(0,rgba(255,255,255,0)),to(#ffffff));
            background: linear-gradient(90deg,rgba(255,255,255,0) 0,#ffffff);
            pointer-events: none;
        }
    }

    .info-container {
        overflow-x:auto;
        width: 100%;
        max-width: 100%;
    }

    .short-info-overview {
        position: relative;
        height: 190px;
        max-width: 100%;

        &::after {
            content: "";
            display: block;
            background: $color-4;
            bottom: 20%;
            left: unset;
            width: inherit;
            height: 4px;
            position: absolute;
        }
    }

    button.short-info-tab {
        border-radius: 50%;
        max-height: 20px;
        max-width: 20px;
        padding: 0;
        margin: 0;
        background: $color-4;
        height: 20px;
        width: 20px;
        position: absolute;
        z-index: 1;
        border: 2px solid #F6F4F2;
        cursor: pointer;
        bottom: calc(20% - 8px);
        display: flex;
        align-items: flex-end;
        transform: rotate(-45deg);


        &.pulse-animation {
            animation-name: pulse;
            animation-duration: 0.6s;
            animation-timing-function: ease-out;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            animation-play-state: running;
        }

        &.selected {
            background-color: #AB1C46;

            span.tab-title {
                color: #AB1C46;
            }
        }

        span.tab-title {
            padding-left: 26px;
            font-family: $font-base;
            font-weight: 600;
            font-size: 16px;
            left: 0px;
            bottom: 48px;
            white-space: nowrap;
            color: $color-1;
        }
    }

    @keyframes pulse {
        0% {
            background-color: $color-1;
        }
    }

    .short-infos {
        position: relative;
        padding-bottom: 50px;

        #short-info-select {
            position: absolute;
            width: 150px;
            bottom: 0;
            padding: 6px 10px;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            left: calc(50% - 75px);
            cursor: pointer;

            @include respond-to($tablet) {
                right: 40px;
                top: 30px;
                left: unset;
                bottom: unset;
            }
        }
    }

    .short-info {
        background-color: #fff;
        padding: 0 10px;

        @include respond-to($tablet) {
            padding-top: 50px;
            //padding: 70px 40px 50px 40px;
        }

        &.hidden {
            display: none;
        }

        .info-title {
            min-height: 40px;

            &.hide-on-mobile {
                display: none;
            }

            @include respond-to($tablet) {
                &.hide-on-mobile {
                    display: block;
                }

                &.hide-on-desktop {
                    display: none;
                }

                &.hide-on-desktop.hide-on-mobile {
                    display: none;
                }
            }
        }

        .text-and-image {
            .info-image {
                display: block;
                margin-bottom: 20px;

                @include respond-to($tablet) {
                    float: right;
                    max-width: calc(50% - 50px);
                    margin: 0 0 40px 50px;
                }
            }

            .info-bottom-image {

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
