// ============================================================================
// Module: Gallery
// ============================================================================

.module-gallery {
    .text {
        @include respond-to($tablet) {
            text-align: center;
        }
    }

    .images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 20px;

        @include respond-to($large-phone) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @include respond-to($tablet) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }
    }

    figure {
        float: left;
        width: 18%;
        margin-right: 2%;

        @supports (display: grid) {
            width: auto;
            margin-right: 0;
        }
    }

    img {
        width: 100%;
        height: auto;
        border: 1px solid #eee;
        padding: 3px;
    }

    // Use large photos when ratio 1:1 and screen is larger than mobile
    .large {
        @include respond-to($tablet) {
            grid-column: span 2;
            grid-row: span 2;
        }
    }
}
